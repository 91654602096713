import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {
  getStorage,
  ref as fbStorageRef,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  ref,
  listAll,
  getMetadata,
} from "firebase/storage";
import {
  getDatabase,
  ref as fbDatabaseRef,
  get,
  child,
  update,
} from "firebase/database";

import firebaseConfig from "./FirebaseConfig.json";
import moment from "moment";
import { deepClone } from "src/utils/utils";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

class FirebaseService {
  constructor() {
    this._app = initializeApp(firebaseConfig);
    this.initialize();
  }

  initialize() {
    const analytics = getAnalytics(this._app);
  }

  getAuth() {
    return getAuth(this._app);
  }


  getFeedbackDBRef(){
    try {
      const database = getDatabase(this._app,'https://medical-assistant-feedbacks.firebaseio.com');
      const dbRef = fbDatabaseRef(database,'/feedbacks');

      return dbRef;
    } catch (error) {
      console.error("Firebase DB Error:", error);
    }

    return null;
  }
  async getDataSnapshot(uid) {
    try {
      const database = getDatabase(this._app);
      const dbRef = fbDatabaseRef(database);

      const snapshot = await get(child(dbRef, `users/${uid}`));
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return { assets: {}, patients: {}, physicians: {} };
      }
    } catch (error) {
      console.error("Firebase DB Error:", error);
    }

    return null;
  }


  async calcStorageUsage(uid,path=`/users/${uid}/`) {
    const storage = getStorage();
    // console.log("calcStorageUsage::",path)
    const listRef = ref(storage, path);
    let totalSize = 0; // To hold the total size of files

    const calculateSize = async (pathRef) => {
      const res = await listAll(pathRef);
      
      // Calculate size for current directory's files
      for (const itemRef of res.items) {
        const metadata = await getMetadata(itemRef);
        // console.log("meta:",JSON.stringify(metadata).length)
        totalSize += metadata.size+JSON.stringify(metadata).length;
      }
  
      // Recursively calculate size for each subdirectory
      for (const prefix of res.prefixes) {
        await calculateSize(prefix);
      }
    };
  
    try {
      await calculateSize(listRef);
    } catch (error) {
      console.error("Failed to calculate storage usage:", error);
    }
    
    return totalSize; // Returns total size in bytes



  }


  async updateUserRecord(uid, personId, recordId, record) {
    if (!uid) return;
    try {
      const database = getDatabase();
      const path = `users/${uid}/patients/${personId}/data/${recordId}`;
      const updates = {};
      const finalRecord = deepClone(record);
      if (finalRecord.renderFields) delete finalRecord.renderFields;
      if (finalRecord.renderFieldInfo) delete finalRecord.renderFieldInfo;
      if (finalRecord.getRecordInfoTitle) delete finalRecord.getRecordInfoTitle;
      if (finalRecord.newAssets) delete finalRecord.newAssets;
      if (finalRecord.removedAssets) delete finalRecord.removedAssets;

      updates[path] = finalRecord;
      await update(fbDatabaseRef(database), updates);
    } catch (error) {
      console.error("record-update-error", error);
    }
  }

  async removeUserRecord(uid, personId, recordId) {
    if (!uid) return;
    try {
      const database = getDatabase();
      const path = `users/${uid}/patients/${personId}/data/${recordId}`;
      const updates = {};
      updates[path] = {
        id: recordId,
        _isDeleted: true,
        _updateTime: moment().unix(),
      };
      await update(fbDatabaseRef(database), updates);
    } catch (error) {
      console.error("record-remove-error", error);
    }
  }

  async updatePersonInfo(uid, personInfo) {
    if (!uid) return;
    try {
      const database = getDatabase();
      const path = `users/${uid}/patients/${personInfo.id}/info`;
      const updates = {};
      const finalPersonInfo = deepClone(personInfo);
      if (finalPersonInfo.avatarUrl) delete finalPersonInfo.avatarUrl;
      if (finalPersonInfo.newAvatar) delete finalPersonInfo.newAvatar;
      if (finalPersonInfo.removedAvatar) delete finalPersonInfo.removedAvatar;
      updates[path] = finalPersonInfo;
      await update(fbDatabaseRef(database), updates);
    } catch (error) {
      console.error("person-update-error", error);
    }
  }

  async updatePersonTags(uid, personId, tags) {
    if (!uid) return;
    try {
      const database = getDatabase();
      const path = `users/${uid}/patients/${personId}/tags`;
      const updates = {};
      let finalTags = {};
      tags.forEach((t) => {
        finalTags[t.id] = t;
      });
      updates[path] = finalTags;
      await update(fbDatabaseRef(database), updates);
    } catch (error) {
      console.error("person-update-tags-error", error);
    }
  }

  async storeNewPerson(uid, person) {
    if (!uid) return;
    try {
      const database = getDatabase();
      const path = `users/${uid}/patients/${person.info.id}`;
      const updates = {};
      const finalPerson = deepClone(person);
      updates[path] = finalPerson;
      await update(fbDatabaseRef(database), updates);
    } catch (error) {
      console.error("person-add-error", error);
    }
  }

  async removePerson(uid, personId) {
    if (!uid || !personId) return;
    try {
      const database = getDatabase();
      const path = `users/${uid}/patients/${personId}`;
      const updates = {};
      updates[path] = {
        id: personId,
        _isDeleted: true,
        _updateTime: moment().unix(),
      };
      await update(fbDatabaseRef(database), updates);
    } catch (error) {
      console.error("person-remove-error", error);
    }
  }

  async getAssetDownloadUrl(remoteUri) {
    try {
      const storage = getStorage();

      const storageRef = fbStorageRef(storage, `/${remoteUri}`);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error("download-url-error", error);
      return "";
    }
  }

  async storeNewAssets(uid, personId, newAssets) {
    const addedAssets = {};
    try {
      const database = getDatabase();
      for (let asset of newAssets) {
        if (!asset.name) continue;
        const assetId = await this.storeAsset(uid, personId, asset);
        if (assetId) {
          const assetObj = {
            aID: assetId,
            fileName: asset.name,
            remoteUri: `users/${uid}/assets/${personId}/${assetId}`,
            uID: personId,
          };

          const path = `users/${uid}/assets/${assetId}`;
          const updates = {};
          updates[path] = assetObj;
          await update(fbDatabaseRef(database), updates);
          addedAssets[assetId] = assetObj;
        }
      }

      return addedAssets;
    } catch (error) {
      console.error("add-assets-error", error);
      return {};
    }
  }

  async storeAsset(uid, personId, file) {
    if (!uid) return null;
    if (!file || !file.name) {
      return null;
    }

    try {
      const storage = getStorage();
      const assetId = `${personId}${moment().unix()}`;
      const path = `/users/${uid}/assets/${personId}/${assetId}`;
      const storageRef = fbStorageRef(storage, path);
      await uploadBytes(storageRef, file);

      return assetId;
    } catch (error) {
      console.error("file-upload-error", error);
      return null;
    }
  }

  getAsset(assets, assetId) {
    if (!assetId || !assets || !(assetId in assets)) return null;
    return assets[assetId];
  }

  async removeAssets(uid, personId, assetIds) {
    if (!uid || !personId) return false;
    try {
      const database = getDatabase();
      for (let assetId of assetIds) {
        if (!assetId) continue;
        const path = `users/${uid}/assets/${assetId}`;
        const updates = {};
        updates[path] = {
          hash: "",
          localUri: "",
          _isDeleted: true,
        };
        await update(fbDatabaseRef(database), updates);
        await this.removeAsset(uid, personId, assetId);
      }
    } catch (error) {
      console.error("assets-remove-error", error);
      return false;
    }

    return true;
  }

  async removeAsset(uid, personId, assetId) {
    if (!uid) return false;
    try {
      const storage = getStorage();
      const path = `/users/${uid}/assets/${personId}/${assetId}`;
      const storageRef = fbStorageRef(storage, path);
      await deleteObject(storageRef);

      return true;
    } catch (error) {
      console.error("file-remove-error", error);
      return false;
    }
  }

  async getAssetUrlById(assets, assetId) {
    if (!assetId) return "";
    const asset = this.getAsset(assets, assetId);
    return asset ? await this.getAssetDownloadUrl(asset.remoteUri) : "";
  }
}

export const firebaseService = new FirebaseService();
export default FirebaseService;

import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { deepClone } from "src/utils/utils";

const initialState = {
  assets: {},
  persons: {},
  physicians: {},
  selectedPersonId: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const payload = action.payload
        ? action.payload
        : { assets: {}, patients: {}, physicians: {} };
      const { assets, patients, physicians } = payload;
      
      let validPersons = {};
      const patientArray = Object.values(patients ? patients : []);
      patientArray.forEach((p) => {
        if ("_isDeleted" in p || !p.info?.id) return;
        validPersons[p.info.id] = { ...p };
      });

      const selectedPersonId =
        Object.values(validPersons).length > 0
          ? Object.values(validPersons)[0].info.id
          : null;

      const newState = {
        assets: assets ? assets : {},
        persons: validPersons ? validPersons : {},
        physicians: physicians ? physicians : {},
        selectedPersonId: selectedPersonId,
      };

      return newState;
    },

    setSelectedPerson: (state, action) => {
      return {
        ...state,
        selectedPersonId: action.payload,
      };
    },

    setUserRecords: (state, action) => {
      const { personId, records, tags } = action.payload;
      let persons = cloneDeep(state.persons);
      if (!persons[personId]) return state;
      persons[personId].data = deepClone(records);
      persons[personId].tags = deepClone(tags);

      return {
        ...state,
        persons: persons,
      };
    },

    setUserPersonInfo: (state, action) => {
      const { personId, info } = action.payload;
      let persons = cloneDeep(state.persons);
      if (!persons[personId]) return state;
      persons[personId].info = deepClone(info);

      return {
        ...state,
        persons: persons,
      };
    },

    addUserPerson: (state, action) => {
      const newPerson = action.payload;
      let persons = deepClone(state.persons);
      persons[newPerson.id] = newPerson;

      return {
        ...state,
        persons: persons,
      };
    },

    removeUserPerson: (state, action) => {
      const personId = action.payload;
      let persons = deepClone(state.persons);
      let selectedPersonId = state.selectedPersonId;
      if (!persons[personId]) return;
      delete persons[personId];

      if (selectedPersonId === personId) {
        selectedPersonId =
          Object.keys(persons).length > 0 ? Object.keys(persons)[0] : null;
      }

      return {
        ...state,
        persons: persons,
        selectedPersonId: selectedPersonId,
      };
    },

    setUserAssets: (state, action) => {
      return {
        ...state,
        assets: action.payload,
      };
    },
  },
});

export const {
  setSelectedPerson,
  setUserData,
  setUserPersonInfo,
  addUserPerson,
  removeUserPerson,
  setUserRecords,
  setUserAssets,
} = dataSlice.actions;

export default dataSlice.reducer;

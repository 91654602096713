import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import PersonSelector from "../PersonSelector";

// Imports that previously had type imports
import { setCompactView } from "src/reducers/preferencesSlice";
import MenuDrawerButton from "../MenuDrawerButton";
import SearchBar from "../SearchBar";
import Syncer from "../Syncer";

import AppLogo from "src/assets/App_Logo.png";

import "./Header.scss";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";

const HeaderEmpty = ({title=""}) => {
  const dispatch = useDispatch();
  const { isWide, compactView, pageClickActions } = useSelector(
    (state) => state.preferences // Removed type annotation
  );

  const onMenuClick = () => {
    dispatch(setCompactView(!compactView));
    // console.log("isOpen:",isOpen)
    // handleMenu();
  };
  // console.log("isWide:",isWide,",compactView",compactView)

  return (
    <div
      className="pageheader"
      // onClick={() => {
      //   Object.values(pageClickActions).forEach((action) => action());
      // }}
    >
      <div
        className={`pageHeaderTitle 
       
        ${!isWide && compactView  ? "pageHeaderTitleCompact" : ""}
        ${isWide ? "" : "pageHeaderTitleSmall"}
        `}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onMenuClick}
          edge="start"
          // size="large"
          sx={{margin:1.5}}
        >
       
            <MenuIcon className="menuIcon" />
       
        </IconButton>
        <img src={AppLogo} alt="ARKA" className={`logoIcon
        
         ${!isWide && compactView  ? "logoIconCompact" : ""}
         `} />
        <span>
        My Health Records
        </span>
         

        
      </div>
      
      <div className={`pageHeaderSpace
      ${isWide ? "" : "pageHeaderSpaceSmall"}
      ${(compactView && !isWide) ? "pageHeaderSpaceCompact" : ""}
      `}>
      <div className="TitleContainer">
        {title}
        </div>
        <Syncer />
      </div>
     
      
    </div>
  );
};

export default HeaderEmpty;

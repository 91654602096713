
import { authService } from "src/services/AuthService";
import {push}  from "firebase/database";
import { firebaseService } from "./FirebaseService";

// import CryptoJS from 'crypto-js';

// Assuming firebase is already initialized elsewhere in your project

export const sendFeedback = async (feedback, callback) => {
    try {
        const data = JSON.parse(JSON.stringify(feedback));
        
        // Simulate device data as much as possible or remove if not applicable
        data.device = {
            
            systemVersion: navigator.userAgent
        };

        data.dateTime = new Date().toISOString();

        // Example: Replace with actual data or remove if not applicable
        data.app_id = "com.arkaagp.medirecords";
        data.versionCode = "0";
        data.lang = navigator.language;

        // User data from Firebase auth (if user is logged in)
        data.user = {};
        const currentUser = authService.getUser();
        if (currentUser) {
            data.user.email = currentUser.email;
            data.user.uid = currentUser.uid;
        }
        // console.log("data::",data)
        // Getting FCM token for the web
        // const messaging = firebase.messaging();
        // try {
        //     await messaging.requestPermission();
        //     const fUserToken = await messaging.getToken();
        //     data.user.fUserToken = fUserToken;
        // } catch (error) {
        //     console.log("Error getting messaging token: ", error);
        // }

        // Here you might set other data or manipulate it as needed

        // Show alert equivalent (adjust to your web UI logic)
        console.log("Please Wait ....");
        const feedbackRef=firebaseService.getFeedbackDBRef()
        // console.log("feedbackRef::",feedbackRef)
        // const pushRef=await push(feedbackRef)
        // console.log("pushObj::",pushRef)
        push(feedbackRef,data).then(() => {
            callback(true);
        }).catch((e) => {
            console.warn("Error submitting feedback: ", e);
            callback(false);
        });

    } catch (e) {
        console.warn("Global Error:", e);
        callback(false);
    }
};

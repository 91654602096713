import React from "react";
import { Trash } from "react-bootstrap-icons";

import TextField from "../TextField";

import "./RecordEditorAssetRow.scss";

interface IRecordEditorAssetRowProps {
  id: string;
  name: string;
  onDelete: (id: string) => void;
}

function RecordEditorAssetRow({
  id,
  name,
  onDelete,
}: IRecordEditorAssetRowProps) {
  return (
    <div className="recordEditorRow recordEditorAssetRow" key={id}>
      <TextField className="recordEditorAssetText" label="File" value={name} />
      <Trash className="recordEditorAssetDelete" onClick={() => onDelete(id)} />
    </div>
  );
}

export default RecordEditorAssetRow;

import React from "react";
import MUIAutocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete";
import TextField from "src/components/TextField";

import "./AutoComplete.scss";

interface IAutoComplete {
  onSelect?: (value: string) => void;
  suggestions: string[];
  title: string;
  value?: string;
}

interface IOptionType {
  inputValue?: string;
  title: string;
}

function AutoComplete({ onSelect, suggestions, title, value }: IAutoComplete) {
  const [text, setText] = React.useState<IOptionType | null>(
    value ? { inputValue: "", title: value } : null
  );

  const filter = createFilterOptions<IOptionType>();

  return (
    <MUIAutocomplete
      className="autoCompleteView"
      autoHighlight
      value={text}
      size="small"
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setText({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          onSelect && onSelect(newValue.inputValue);
          // Create a new value from the user input
          setText({
            title: newValue.inputValue,
          });
        } else {
          newValue && onSelect && onSelect(newValue.title);
          setText(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== "" && !isExisting) {
          filtered.unshift({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={`auto-complete-${title}`}
      options={suggestions.map((s) => ({ inputValue: "", title: s }))}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      freeSolo
      renderInput={(params) => <TextField {...params} label={title} />}
    />
  );
}

export default AutoComplete;

import React from "react";
import AimoSearchBar from "@aimo.ui/aimo-searchbar";
import { Search } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";

import { setSearchText } from "src/reducers/preferencesSlice";

import "./SearchBar.scss";

const SearchBar = () => {
  const dispatch = useDispatch();

  return (
    <AimoSearchBar
      className="searchBarContainer"
      onChange={(text: string) => {
        dispatch(setSearchText(text));
      }}
      renderIcon={() => {
        return <Search className="searchBarIcon" />;
      }}
    />
  );
};

export default SearchBar;

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store";

/* Fonts */
import "./fonts/LoftygoalsRegular.otf";

import "./index.scss";
if (process.env.NODE_ENV !== "development")
    console.log = () => {};

    
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

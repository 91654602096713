import React, { useState } from "react";
import { GoogleAuthProvider, User, signInWithPopup } from "firebase/auth";

import { firebaseService } from "src/services/FirebaseService";
import Button3D from "../Button3D";

import GoogleIcon from "./assets/google.svg";

import "./GoogleLoginButton.scss";
import { Button } from "@mui/material";

interface IGoogleLoginButtonProps {
  className?: string;
  extraClickAction?: () => void;
  onError: (error: any) => void;
  onSuccess: (user: User | null) => void;
}

const GoogleLoginButton = ({
  className,
  extraClickAction,
  onError,
  onSuccess,
}: IGoogleLoginButtonProps) => {
  const [waiting, setWaiting] = useState<boolean>(false);
  const handleLoginRequest = async () => {
    extraClickAction && extraClickAction();
    try {
      setWaiting(true);
      const provider = new GoogleAuthProvider();
      const auth = firebaseService.getAuth();
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (!credential) {
        throw new Error("Google-Login Error: No Credentials!");
      }
      const user = result.user;

      onSuccess(auth.currentUser);
    } catch (error) {
      /*TODO: do something about the error! */
      console.error("Google-Login Failed: ", error);
      onError(error);
    } finally {
      setWaiting(false);
    }
  };

  return (
    <Button
      className="googleLoginContainer"
      variant="contained" 
                color="primary" 
      disabled={waiting}
      onClick={handleLoginRequest}
    >
      <div className="googleLoginIconContainer">
        <img alt="G" src={GoogleIcon} />
      </div>
      <span className="googleLoginText">Continue with Google</span>
    </Button>
  );
};

export default GoogleLoginButton;

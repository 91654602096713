import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";
import RecordInfoAsset from "./RecordInfoAsset";

import "./RecordInfoDocument.scss";

export const renderFieldInfo4Document: IRenderFieldInfo = (
  record: IRecord,
  allData
) => {
  return (
    <React.Fragment>
      {record.fields?.Document?.Description && (
        <div className="recordFieldInfoContainer">
          <span className="recordFieldInfoLabel">
            {record.fields.Document.Description}
          </span>
        </div>
      )}
      <RecordInfoAsset assets={allData.assets} record={record} />
    </React.Fragment>
  );
};

export const getRecordInfoTitle4Document: GetRecordInfoTitleFunc = (record) => {
  const title = record.fields?.Document?.Title;
  return title ? title : "";
};

import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";

import "./RecordInfoSymptoms.scss";

export const renderFieldInfo4Symptoms: IRenderFieldInfo = (record: IRecord) => {
  const symptoms = record.fields?.Symptom?.symptoms;
  if (!symptoms || !Array.isArray(symptoms)) return <React.Fragment />;
  const symptomStr = symptoms.join(" , ");
  return (
    <React.Fragment>
      <div className="recordFieldInfoContainer recordFieldInfoSymptoms">
        <span className="recordFieldInfoLabel">Symptom(s):</span>
        <span className="recordFieldInfoValue">{symptomStr}</span>
      </div>
    </React.Fragment>
  );
};

export const getRecordInfoTitle4Symptoms: GetRecordInfoTitleFunc = (record) => {
  return "";
};

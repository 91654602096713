import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "src/components/Modal";
import PersonItem from "src/components/PersonItem";
import PersonEditor from "src/components/PersonEditor";
import { deepClone } from "src/utils/utils";
import {
  addUserPerson,
  removeUserPerson,
  setUserAssets,
  setUserPersonInfo,
  setSelectedPerson
} from "src/reducers/dataSlice";
import { Button } from "@mui/material";
import PersonService from "src/services/PersonService";
import Prompt from "src/components/Prompt";
import { setLoading } from "src/reducers/preferencesSlice";
import { assetService } from "src/services/AssetService";

import "./PersonPage.scss";
import Page from "src/components/Page";
import { useNavigate } from "react-router-dom";


const PersonPage = () => {
  const allData = useSelector((state) => state.data); // Removed RootState type
  const { pageClickActions, searchText } = useSelector(
    (state) => state.preferences // Removed RootState type
  );
  const [editMode, setEditMode] = useState(false);
  const [editingItem, setEditingItem] = useState(null); // Removed IPersonInfo type
  const [personInfos, setPersonInfos] = useState([]); // Removed IPersonInfo[] type
  const [removingItem, setRemovingItem] = useState(null); // Removed IPersonInfo type

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const personService = new PersonService();

  const preparePersonInfos = async (personInfos) => { // Removed IPersonInfo[] type
    dispatch(setLoading(true));
    let newPersonInfos = deepClone(personInfos);
    for (let index in newPersonInfos) {
      let url = "";
      if (newPersonInfos[index].avatarId) {
        url = await assetService.getAssetUrlById(
          allData.assets,
          newPersonInfos[index].avatarId
        );
      }

      newPersonInfos[index] = { ...newPersonInfos[index], avatarUrl: url };
    }

    setPersonInfos(newPersonInfos);
    dispatch(setLoading(false));
  };

  const prepareInfos = async () => {
    const personArray = Object.values(allData.persons).filter(
      (p) => !("_isDeleted" in p)
    );
    const newPersonInfos = personArray.map(({ data, info }) => {
      return info;
    });
    await preparePersonInfos(newPersonInfos);
  };

  useEffect(() => {

    prepareInfos();
  }, [allData]);

  const handlePersonEdit = (person) => { // Removed IPersonInfo type
    setEditingItem(person);
    setEditMode(true);
  };

  const handlePersonRemove = async (person) => { // Removed IPersonInfo type
    const modifiedPersonInfos = await personService.doRemovePerson(
      person.id,
      personInfos
    );
    if (!modifiedPersonInfos) return null;
    setPersonInfos(modifiedPersonInfos);
    dispatch(removeUserPerson(person.id));
  };

  const handlePersonAdd = async () => {
    let allPersonInfos = deepClone(personInfos);
    const newPersonInfo = personService.createNewPersonInfo(); // Removed IPersonInfo type
    setEditMode(true);
    setEditingItem(newPersonInfo);
  };

  const handleModalClose = () => {
    setEditMode(false);
    setEditingItem(null);
  };

  const handlePromptClose = () => {
    setRemovingItem(null);
  };

  const handlePersonChange = async (personInfo) => { // Removed IPersonInfo type
    setEditMode(false);
    setEditingItem(null);

    const result = await personService.doModifyPerson(
      personInfo,
      personInfos,
      allData
    );
    if (!result) return;

    const {
      assets: modifiedAssets,
      newPerson,
      personInfos: modifiedPersonInfos,
      personInfo: modifiedPersonInfo,
    } = result;
    if (newPerson) {
      dispatch(addUserPerson(newPerson));
    }
    dispatch(setUserAssets(modifiedAssets));
    setPersonInfos(modifiedPersonInfos);
    dispatch(
      setUserPersonInfo({
        personId: modifiedPersonInfo.id,
        info: modifiedPersonInfo,
      })
    );
  };

  const filteredPersons = useMemo(() => {
    const text2Search = searchText.toLocaleLowerCase();
    if (!text2Search) return personInfos;
    return personInfos.filter((p) => {
      const replacer = (key, value) => {
        if (value && typeof value === "object") {
          var replacement = {};
          let index = 0;
          for (let k in value) {
            replacement[index++] = value[k];
          }
          return replacement;
        }
        return value;
      };

      const stringified = JSON.stringify(p, replacer).toLocaleLowerCase();
      return stringified.includes(text2Search);
    });
  }, [searchText, personInfos]);

  return (
    <Page>
      <div
        className="personPageContainer"
        onClick={() => {
          Object.values(pageClickActions).forEach((action) => action());
        }}
      >
        <div className="personsContainer">
          {filteredPersons.length > 0 ? (
            filteredPersons.map((p) => {
              return (
                <PersonItem
                  key={p.id}
                  onEdit={handlePersonEdit}
                  onRemove={(personInfo) => { // Removed IPersonInfo type
                    setRemovingItem(personInfo);
                  }}
                  person={p}
                  onClick={()=>{
                    dispatch(setSelectedPerson(p.id))
                    navigate('/records')
                    
                  }}
                />
              );
            })
          ) : (
            <div className="emptyPageContainer">
              <div className="emptyPageMessageContainer">No Persons to Show!</div>
            </div>
          )}
        </div>
        {/* <div className="personAddContainer">
          <div className="personAddButtonContainer">
            <Button
              className="personAddButton"
              variant="contained"
              onClick={() => {
                handlePersonAdd();
              }}
            >
              Add Person
            </Button>
          </div>
        </div> */}
        {editingItem && (
          <Modal
            className="editPersonModal"
            onClose={handleModalClose}
            title={editingItem.name}
            visible={editMode}
          >
            <PersonEditor onChange={handlePersonChange} person={editingItem} />
          </Modal>
        )}
        {removingItem && (
          <Prompt
            className="editRecordModal"
            onCancel={handlePromptClose}
            onOk={() => {
              setRemovingItem(null);
              handlePersonRemove(removingItem);
            }}
            message="Are you sure?"
          />
        )}
      </div>
    </Page>
  );
};

export default PersonPage;

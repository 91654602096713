import React from "react";

import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";
import { IData } from "src/types/IData";
import RecordEditorAsset from "./RecordEditorAsset";
import { cloneRecord } from "./RecordEditorFields";

import "./RecordEditorPathology.scss";

export const cloneRecord4Radiology = (record: IRecord): IRecord => {
  let cloned = cloneRecord(record);
  if (!cloned.fields.Radiology) cloned.fields.Radiology = {};
  if (!cloned.fields.Radiology.Title) cloned.fields.Radiology.Title = "";
  if (!cloned.fields.Radiology.Result) cloned.fields.Radiology.Result = "";

  return cloned;
};

export const renderFields4Pathology: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  return (
    <React.Fragment>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorPathologyTitle"
          label="Title"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4Radiology(record);
            modifiedRecord.fields.Pathology.Title = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Pathology?.Title}
        />
      </div>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorPathologyResult"
          label="Result"
          multiline={true}
          maxRows={3}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4Radiology(record);
            modifiedRecord.fields.Pathology.Result = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Pathology?.Result}
        />
      </div>
      <RecordEditorAsset
        assets={allData.assets}
        record={record}
        handleFieldsChange={handleFieldsChange}
      />
    </React.Fragment>
  );
};

import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";

import "./RecordInfoPhysicalExam.scss";

export const renderFieldInfo4PhysicalExam: IRenderFieldInfo = (
  record: IRecord
) => {
  return (
    <React.Fragment>
      {record.fields?.Height?.value && (
        <div className="recordFieldInfoContainer">
          <span className="recordFieldInfoLabel">Height:</span>
          <span className="recordFieldInfoValue">
            {record.fields.Height.value}&nbsp;
            {record.fields.Height.unit}
          </span>
        </div>
      )}
      {record.fields?.Weight?.value && (
        <div className="recordFieldInfoContainer">
          <span className="recordFieldInfoLabel">Weight:</span>
          <span className="recordFieldInfoValue">
            {record.fields.Weight.value}&nbsp;
            {record.fields.Weight.unit}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export const getRecordInfoTitle4PhysicalExam: GetRecordInfoTitleFunc = (
  record
) => {
  return "";
};

import React, { useEffect, useState } from "react";


import AppLogo from "src/assets/App_Logo.png";
import "./VerifyLinkPage.scss";
import { Button } from "@mui/material";




const VerifyLinkPage = () => {

  

  return (
    <div className="loginPageContainer">
        <div className="loginLogo">
          <img src={AppLogo} alt="ARKA" />
          <span>My Health Records</span>
        </div>
        <div className="verifyContainer">  
          
          <h2>Instructions have been sent to your inbox.</h2>
          <div>Please check both your inbox and spam folder.</div>
          <div>If you don’t receive the email within 5 minutes, contact our support team .</div>

          <div  className="loginButtonContainer">
            <Button 
                variant="contained" 
                color="primary" 
                className="loginButton"
                onClick={()=>window.location.replace("/login")}
                >
              Back to Login Page
            </Button>
          </div>
        
        </div>
     
        
    </div>
  );
};



export default VerifyLinkPage;

import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";
import RecordInfoAsset from "./RecordInfoAsset";
import { IData } from "src/types/IData";

import "./RecordInfoRadiology.scss";

export const renderFieldInfo4Radiology: IRenderFieldInfo = (
  record: IRecord,
  allData: IData
) => {
  return (
    <React.Fragment>
      {record.fields?.Radiology?.Result && (
        <div className="recordFieldInfoContainer">
          <span className="recordFieldInfoLabel">Result:</span>
          <span className="recordFieldInfoValue recordRadiologyResult">
            {record.fields.Radiology.Result}
          </span>
        </div>
      )}
      <RecordInfoAsset assets={allData.assets} record={record} />
    </React.Fragment>
  );
};

export const getRecordInfoTitle4Radiology: GetRecordInfoTitleFunc = (
  record
) => {
  const title = record.fields?.Radiology?.Title;
  return title ? title : "";
};

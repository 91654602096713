import React, { useEffect, useState } from "react";
import AimoSideMenu from "@aimo.ui/aimo-sidemenu";
import { Clipboard2Pulse, People, Power } from "react-bootstrap-icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import UserProfile from "../UserProfile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { authService } from "src/services/AuthService";
import AppLogo from "src/assets/App_Logo.png";

import "./Menu.scss";
import { addClickAction, setCompactView } from "src/reducers/preferencesSlice";
import MenuDrawerButton from "../MenuDrawerButton";

import { Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import { MenuItem } from "./MenuItem";
import AccountMenu from "./AccountMenu";
import { Link } from "react-router-dom";

const Menu = ({isOpen, handleMenu}) => {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [selIndex,setSelIndex]=useState(-1)
  const [accMenuOpen,setAccMenuOpen]=useState(false)

  const { compactView, isWide } = useSelector(
    (state) => state.preferences
  );
  let path = location.pathname;
  if (path === "/") path = "/records";

  const onMenuItemClick=(index)=>{
    console.log("INDEX::",index)
    setSelIndex(index)
  }
  const handleOutsideClick = (isWide) => {
    if (!isWide) {
      dispatch(setCompactView(true));
    }
  };
  const onMenuClick=()=>{
    dispatch(setCompactView(isOpen));
    handleMenu()
  }

  const handleAccountMenu=()=>{
    dispatch(setCompactView(false));
    setAccMenuOpen(true)
  }

  useEffect(() => {
    dispatch(
      addClickAction({
        owner: "menu",
        action: () => handleOutsideClick(isWide),
      })
    );
  }, [isWide]);

  const handleLogoutRequest = () => {
    authService.removeAuth();

    window.location.replace("/");
  };

  
 

  return (
    <div
      className={`appMenuContainer 
      ${isWide ? "" : "appMenuContainerSmall"}
      ${compactView ? "appMenuContainerCompact" : ""}
      `}
    >

    <Box sx={{ width: '100%',  maxWidth: 360, bgcolor: 'background.paper', display:'flex', flexDirection: 'column', height:'inherit' }}>
      {/* <ListItem disablePadding sx={{ display: 'block' }}> */}
        {/* <MenuItem text="Dashboard" iconName={"person_outline"} isSelected={(path==='/')}  compactView={compactView} /> */}
        <div className="appMenuListContainer">
        {/* {compactView?(
            <div
              className="menuItemIcon logoutIconContainer"
              onClick={handleLogoutRequest}
            >
              
              <Power className="logoutIcon" />
            </div>
          ):(
         <UserProfile className="menuProfileContainer" to="/account" compactView={compactView}/>
          )} */}
         <UserProfile className="menuProfileContainer" onClick={handleAccountMenu} compactView={compactView}/> 
         {/* <MenuItem text="Logout" iconName={"logout"} isSelected={false}  compactView={compactView} onClick={handleLogoutRequest} /> */}
        <hr />
        <MenuItem text="History" iconName={"filetext"} isSelected={(path==='/records')}  compactView={compactView} path="/records" />
        <MenuItem text="Persons" iconName={"person_outline"} isSelected={(path==='/persons')}  compactView={compactView} path="/persons" />
        <hr />
        {/* <MenuItem text="Settings" iconName={"setting"} isSelected={(path==='/settings')}  compactView={compactView} path="/settings" /> */}
        <MenuItem text="Feedback" iconName={"customerservice"} isSelected={(path==='/feedback')}  compactView={compactView} path="/feedback" />
        <MenuItem text="Download App" iconName={"download"} isSelected={(path==='/downloads')}  compactView={compactView} path="/downloads" />
        </div>
        
        
      {/* </ListItem> */}
      <AccountMenu onClose={()=>setAccMenuOpen(false)} open={accMenuOpen} logoutClick={handleLogoutRequest}/>
    </Box> 
    
      {/* <AimoSideMenu
        compactView={isWide ? false : compactView}
        hideHeader={true}
        hideIcons={true}
        menuItems={menuItems}
        containerClassName="appMenu"
      /> */}
      <div className={`bottomLinksContainer
          ${compactView ? "bottomLinksContainerCompact" : ""}   
          `}>
        <a href="/privacy_policy.html" target="_blank" >Privacy Policy</a>
        <a href="/tos.html" target="_blank" >Terms of Services</a>
      </div>
    </div>
  );
};

export default Menu;

import React from "react";

import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";

import "./RecordEditorSurgery.scss";
import { IData } from "src/types/IData";
import RecordEditorAsset from "./RecordEditorAsset";
import { cloneRecord } from "./RecordEditorFields";

export const cloneRecord4Surgery = (record: IRecord): IRecord => {
  let cloned = cloneRecord(record);
  if (!cloned.fields.Surgery) cloned.fields.Surgery = {};
  if (!cloned.fields.Surgery.Title) cloned.fields.Surgery.Title = "";
  if (!cloned.fields.Surgery.Description)
    cloned.fields.Surgery.Description = "";

  return cloned;
};

export const renderFields4Surgery: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  return (
    <React.Fragment>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorSurgeryTitle"
          label="Surgery Title"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4Surgery(record);
            modifiedRecord.fields.Surgery.Title = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Surgery?.Title}
        />
      </div>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorSurgeryDesc"
          label="Surgery Description"
          maxRows={3}
          multiline={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4Surgery(record);
            modifiedRecord.fields.Surgery.Description = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Surgery?.Description}
        />
      </div>
      <RecordEditorAsset
        assets={allData.assets}
        record={record}
        handleFieldsChange={handleFieldsChange}
      />
    </React.Fragment>
  );
};

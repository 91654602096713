import { authService } from "./AuthService";
import { firebaseService } from "./FirebaseService";

class DataService {
  initialize = () => {
    firebaseService.initialize();
  };

  doGetData = () => {
    const uid = authService.getUId();
    if (!uid) return null;

    return firebaseService.getDataSnapshot(uid);
  };
}

export const dataService = new DataService();
export default DataService;

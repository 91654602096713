import React from "react";

import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";

import "./RecordEditorPrescription.scss";
import { IData } from "src/types/IData";
import RecordEditorAsset from "./RecordEditorAsset";
import { cloneRecord } from "./RecordEditorFields";
import { deepClone } from "src/utils/utils";

export const cloneRecord4Prescription = (record: IRecord): IRecord => {
  let cloned = cloneRecord(record);
  if (!cloned.fields.Prescription) cloned.fields.Prescription = {};
  if (!cloned.fields.Diagnosis) cloned.fields.Diagnosis = {};
  if (!cloned.fields.Diagnosis.diagnoses)
    cloned.fields.Diagnosis.diagnoses = [];
  if (!cloned.fields.Prescription.prescriptions)
    cloned.fields.Prescription.prescriptions = [];

  return cloned;
};

export const renderFields4Prescription: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  const diagLen = record.fields?.Diagnosis?.diagnoses?.length;
  let diagValues = diagLen ? record.fields.Diagnosis.diagnoses : [];
  const prescLen = record.fields?.Prescription?.prescriptions?.length;
  let prescValues = prescLen ? record.fields.Prescription.prescriptions : [];
  return (
    <React.Fragment>
      {Array.from(new Array(diagValues.length)).map((v, index) => {
        const value = index < 0 ? "" : diagValues[index];
        return (
          <div key={index} className="recordEditorRow">
            <TextField
              className="recordEditorPrescription"
              label="Diagnosis"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let modifiedRecord = cloneRecord4Prescription(record);
                let modifiedValues = deepClone(diagValues);
                modifiedValues[index] = event.target.value;
                modifiedRecord.fields.Diagnosis.diagnoses = modifiedValues;
                handleFieldsChange(modifiedRecord);
              }}
              value={value}
            />
            {index === 0 ? (
              <PlusCircle
                className="recordEditorPrescriptionPlus"
                onClick={() => {
                  let modifiedRecord = cloneRecord4Prescription(record);
                  let modifiedValues = deepClone(diagValues);
                  modifiedValues.unshift("");
                  modifiedRecord.fields.Diagnosis.diagnoses = modifiedValues;
                  handleFieldsChange(modifiedRecord);
                }}
              />
            ) : (
              <DashCircle
                className="recordEditorPrescriptionMinus"
                onClick={() => {
                  let modifiedRecord = cloneRecord4Prescription(record);
                  let modifiedValues = deepClone(diagValues);
                  modifiedValues.splice(index, 1);
                  modifiedRecord.fields.Diagnosis.diagnoses = modifiedValues;
                  handleFieldsChange(modifiedRecord);
                }}
              />
            )}
          </div>
        );
      })}
      {Array.from(new Array(prescValues.length)).map((v, index) => {
        const value = index < 0 ? "" : prescValues[index];
        return (
          <div key={index} className="recordEditorRow">
            <TextField
              className="recordEditorPrescriptionOrder"
              label="Prescription Order"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let modifiedRecord = cloneRecord4Prescription(record);
                let modifiedValues = deepClone(prescValues);
                modifiedValues[index]["order"] = event.target.value;
                modifiedRecord.fields.Prescription.prescriptions =
                  modifiedValues;
                handleFieldsChange(modifiedRecord);
              }}
              value={value.order}
            />
            <TextField
              className="recordEditorPrescriptionDose"
              label="Prescription Dose"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let modifiedRecord = cloneRecord4Prescription(record);
                let modifiedValues = deepClone(prescValues);
                modifiedValues[index]["dose"] = event.target.value;
                modifiedRecord.fields.Prescription.prescriptions =
                  modifiedValues;
                handleFieldsChange(modifiedRecord);
              }}
              value={value.dose}
            />
            {index === 0 ? (
              <PlusCircle
                className="recordEditorPrescriptionPlus"
                onClick={() => {
                  let modifiedRecord = cloneRecord4Prescription(record);
                  let modifiedValues = deepClone(prescValues);
                  modifiedValues.unshift({ order: "", dose: "" });
                  modifiedRecord.fields.Prescription.prescriptions =
                    modifiedValues;
                  handleFieldsChange(modifiedRecord);
                }}
              />
            ) : (
              <DashCircle
                className="recordEditorPrescriptionMinus"
                onClick={() => {
                  let modifiedRecord = cloneRecord4Prescription(record);
                  let modifiedValues = deepClone(prescValues);
                  modifiedValues.splice(index, 1);
                  modifiedRecord.fields.Prescription.prescriptions =
                    modifiedValues;
                  handleFieldsChange(modifiedRecord);
                }}
              />
            )}
          </div>
        );
      })}
      <RecordEditorAsset
        assets={allData.assets}
        record={record}
        handleFieldsChange={handleFieldsChange}
      />
    </React.Fragment>
  );
};

import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";

import "./RecordInfoSurgery.scss";
import { IData } from "src/types/IData";
import RecordInfoAsset from "./RecordInfoAsset";

export const renderFieldInfo4Surgery: IRenderFieldInfo = (
  record: IRecord,
  allData: IData
) => {
  return (
    <React.Fragment>
      <RecordInfoAsset assets={allData.assets} record={record} />
    </React.Fragment>
  );
};

export const getRecordInfoTitle4Surgery: GetRecordInfoTitleFunc = (record) => {
  const title = record.fields?.Surgery?.Title;
  return title ? title : "";
};

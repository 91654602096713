import { IAsset, IAssets } from "src/types/IAsset";
import { firebaseService } from "./FirebaseService";

class AssetService {
  getAsset = (assets: IAssets, assetId: string) => {
    return firebaseService.getAsset(assets, assetId);
  };

  getAssetUrlById = async (assets: IAssets, assetId: string) => {
    return await firebaseService.getAssetUrlById(assets, assetId);
  };
}

export const assetService = new AssetService();
export default AssetService;

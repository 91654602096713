import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Button } from "@mui/material";
import { PersonCircle, X } from "react-bootstrap-icons";

import { BloodTypes, Genders, IPersonInfo } from "src/types/IPersons";
import { deepClone } from "src/utils/utils";
import DatePicker from "../DatePicker";
import Select from "../Select";
import TextField from "../TextField";

import DefaultAvatar from "src/assets/profile-default.svg";

import "./PersonEditor.scss";

interface IPersonEditorProps {
  onChange: (modifiedPerson: IPersonInfo) => void;
  person: IPersonInfo;
}

const PersonEditor = ({ onChange, person }: IPersonEditorProps) => {
  const [personProps, setPersonProps] = useState<IPersonInfo>(person);
  const avatarRef = useRef<HTMLInputElement | null>(null);
  const [avatarContent, setAvatarContent] = useState<any>(person.avatarUrl);

  const handleAvatarSelect = async (file: File) => {
    const fr = new FileReader();
    fr.onloadend = () => {
      const content = fr.result;
      setAvatarContent(content);
      let modifiedPerson: IPersonInfo = deepClone(personProps);
      modifiedPerson.newAvatar = file;
      delete modifiedPerson.removedAvatar;
      setPersonProps(modifiedPerson);
    };
    fr.readAsDataURL(file);
  };

  const handleAvatarRemove = () => {
    let modifiedPerson: IPersonInfo = deepClone(personProps);
    delete modifiedPerson.newAvatar;
    modifiedPerson.removedAvatar = modifiedPerson.avatarId;
    modifiedPerson.avatarId = "";
    delete modifiedPerson.avatarUrl;
    setAvatarContent(null);
    setPersonProps(modifiedPerson);
  };

  const handlePropChange = (prop: string, value: any): void => {
    const newPerson = deepClone(personProps);
    newPerson[prop] = value;
    setPersonProps(newPerson);
  };

  const submitPersonChanges = () => {
    if (!personProps.name) return;
    onChange && onChange(personProps);
  };

  const bloodTypes = BloodTypes.map((bt) => ({ label: bt, value: bt }));
  const genders = Genders.map((g) => ({ label: g, value: g }));

  return (
    <div className="personEditorContainer">
      <div className="personAvatarContainer">
        {avatarContent ? (
          <img
            alt=""
            className="personAvatar"
            src={avatarContent /* ? avatarContent : DefaultAvatar*/}
            onClick={() => {
              if (avatarRef.current) avatarRef.current.click();
            }}
          />
        ) : (
          <PersonCircle
            className="personAvatarIcon"
            onClick={() => {
              if (avatarRef.current) avatarRef.current.click();
            }}
          />
        )}
        <input
          hidden
          accept="image/*"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (!event.target.files) return;
            const files = Array.from(event.target.files);
            if (files.length === 0) return;
            handleAvatarSelect(files[0]);
          }}
          type="file"
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={avatarRef}
        />
        {avatarContent && (
          <X className="personAvatarRemove" onClick={handleAvatarRemove} />
        )}
      </div>
      <div className="personEditorRows">
        <div className="personEditorRow">
          <TextField
            autoFocus={true}
            error={personProps.name === ""}
            className="personEditorName"
            label="Full Name"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handlePropChange("name", event.target.value);
            }}
            value={personProps.name}
          />
        </div>
        <div className="personEditorRow">
          <DatePicker
            className="taskEditorDeadlineInput"
            title="Birth Date"
            value={new Date(personProps.birthDate)}
            onChange={(value) => {
              const date = moment(value).format("YYYY-MM-DDTHH:mm:ss");
              handlePropChange("birthDate", date);
            }}
            disableTime={true}
          />
        </div>
        <div className="personEditorRow">
          <Select
            className="personEditorGender"
            label="Gender"
            options={genders}
            value={personProps.gender ? personProps.gender : ""}
            onChange={(value) => {
              handlePropChange("gender", value);
            }}
          />
        </div>
        <div className="personEditorRow">
          <Select
            className="personEditorBloodType"
            label="Blood Type"
            options={bloodTypes}
            value={personProps.bloodType ? personProps.bloodType : ""}
            onChange={(value) => {
              handlePropChange("bloodType", value);
            }}
          />
        </div>
      </div>
      <div className="personEditorRow personEditorSaveRow">
        <Button
          className="personEditorSaveButton"
          onClick={() => submitPersonChanges()}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default PersonEditor;

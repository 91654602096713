import moment, { Moment } from "moment";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

import "./DatePicker.scss";

interface IDatePickerProps {
  className?: string;
  onChange?: (value: Moment | null) => void;
  title?: string;
  value: Date | null;
  disableTime?: boolean;
}

const DatePicker = ({
  className,
  onChange,
  title = "Date",
  value,
  disableTime = false,
}: IDatePickerProps) => {
  const selectedDate = value ? moment(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {disableTime ? (
        <MobileDatePicker
          className={`datePickerInput ${className}`}
          value={null}
          label={title}
          onAccept={(value) => {
            onChange && onChange(value);
          }}
        />
      ) : (
        <MobileDateTimePicker
          className={`datePickerInput ${className}`}
          value={selectedDate}
          label={title}
          onAccept={(value) => {
            onChange && onChange(value);
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default DatePicker;

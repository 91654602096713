import React from "react";
import { useSelector } from "react-redux";

import Modal from "../Modal";
import { RootState } from "src/store";

import Loading from "src/assets/loading.svg";

import "./PageLoading.scss";

const PageLoading = () => {
  const { isLoading } = useSelector((state: RootState) => state.preferences);

  return (
    <Modal
      className="loadingModal"
      onClose={() => {}}
      hideTitle={true}
      visible={isLoading}
    >
      <React.Fragment>
        <div className="loadingRotatorBorder">
          <div className="loadingRotator">&nbsp;</div>
        </div>
        <img alt="loading..." src={Loading} className="loadingImage" />
      </React.Fragment>
    </Modal>
  );
};

export default PageLoading;

import React, { useEffect, useState } from "react";
import { Link45deg } from "react-bootstrap-icons";
import { IAsset, IAssets } from "src/types/IAsset";
import { IRecord } from "src/types/IRecord";

import "./RecordInfoAsset.scss";
import { assetService } from "src/services/AssetService";

interface IRecordInfoAssetProps {
  record: IRecord;
  assets: IAssets;
}

function RecordInfoAsset({ record, assets }: IRecordInfoAssetProps) {
  const [recordAssets, setRecordAssets] = useState<IAsset[]>([]);

  const prepareAssets = async () => {
    if (!record.assets) {
      setRecordAssets([]);
      return;
    }

    let rAssets = [];
    for (let assetId of record.assets) {
      const asset = assetService.getAsset(assets, assetId);
      if (!asset || !asset.fileName) continue;
      const url = await assetService.getAssetUrlById(assets, assetId);
      rAssets.push({ ...asset, url: url });
    }
    setRecordAssets(rAssets);
  };

  useEffect(() => {
    prepareAssets();
  }, [record, assets]);

  return (
    <React.Fragment>
      {recordAssets.length > 0 && (
        <div className="recordFieldInfoContainer recordFieldInfoFilesContainer">
          {recordAssets.map((asset) => {
            return (
              <div key={asset.aID} className="recordFieldInfoFileContainer">
                <Link45deg className="recordFieldInfoLabel recordFieldInfoFileIcon" />
                {asset.url ? (
                  <a
                    className="recordFieldInfoLabel recordFieldInfoFile"
                    href={asset.url}
                    target="_blank"
                  >
                    {asset.fileName}
                  </a>
                ) : (
                  <span className="recordFieldInfoValue recordFieldInfoFile">
                    {asset.fileName}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
}

export default RecordInfoAsset;

import React from "react";
import { Button } from "@mui/material";

import Modal from "../Modal";

import "./Prompt.scss";

interface IPromptProps {
  title?: string;
  message: string;
  onOk: () => void;
  onCancel: () => void;
  className?: string;
}

const Prompt = ({
  className,
  title,
  message,
  onOk,
  onCancel,
}: IPromptProps) => {
  return (
    <Modal
      className={`promptModal ${className}`}
      onClose={() => onCancel()}
      title={title ? title : "Warning"}
      visible={true}
    >
      <div>
        <div className="promptModalMessage">{message}</div>
        <div className="promptModalButton">
          <Button variant="contained" className="promptModalOk" onClick={onOk}>
            OK
          </Button>
          {onCancel && (
            <Button
              variant="contained"
              className="promptModalCancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Prompt;

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

function ConfirmDeleteDialog({open, setOpen, doDelete}) {
  
  const [inputValue, setInputValue] = useState('');
  const confirmationPhrase = "DELETE ALL DATA";

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    console.log("Deleting all user data...");
    doDelete()
    // Place your delete logic here
    handleClose();
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const isConfirmationCorrect = inputValue === confirmationPhrase;

  useEffect(()=>{
    setInputValue('')
  },[open])


  return (

      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you absolutely sure?"}</DialogTitle>
        <DialogContent>
          <p>To confirm deletion, type the phrase <strong>{confirmationPhrase}</strong> below:</p>
          <TextField
            autoFocus
            margin="dense"
            id="confirmation"
            label="Confirmation Phrase"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Cancel, keep data</Button>
          <Button onClick={handleDelete} variant="outlined" disabled={!isConfirmationCorrect} color="error">
            Yes, delete all data
          </Button>
        </DialogActions>
      </Dialog>

  );
}

export default ConfirmDeleteDialog;

import React from "react";

import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";
import Select from "../Select";

import "./RecordEditorPhysicalExam.scss";
import { IData } from "src/types/IData";
import { cloneRecord } from "./RecordEditorFields";

const PhysicalExamWeightUnits = {
  Kg: "Kg",
  lb: "lb",
};

const physicalExamHeightUnits = {
  cm: "cm",
  inch: "inch",
  ft: "ft",
};

export const cloneRecord4PhysicalExam = (record: IRecord): IRecord => {
  let cloned = cloneRecord(record);
  if (!cloned.fields.Weight) cloned.fields.Weight = {};
  if (!cloned.fields.Weight.unit) cloned.fields.Weight.unit = "";
  if (!cloned.fields.Weight.value) cloned.fields.Weight.value = "";
  if (!cloned.fields.Height) cloned.fields.Height = {};
  if (!cloned.fields.Height.unit) cloned.fields.Height.unit = "";
  if (!cloned.fields.Height.value) cloned.fields.Height.value = "";

  return cloned;
};

export const renderFields4PhysicalExam: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  const heightUnits = Object.entries(physicalExamHeightUnits).map(
    ([value, label]) => ({ label, value })
  );
  const heightUnitValue = heightUnits.find(
    (u) => u.value === record.fields?.Height?.unit
  )?.value;
  const weightUnits = Object.entries(PhysicalExamWeightUnits).map(
    ([value, label]) => ({ label, value })
  );
  const weightUnitValue = weightUnits.find(
    (u) => u.value === record.fields?.Weight?.unit
  )?.value;

  return (
    <React.Fragment>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorHeight"
          label="Height"
          id="editor-record-physical-height"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4PhysicalExam(record);
            modifiedRecord.fields.Height.value = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Height?.value}
        />
        <Select
          className="recordEditorHeightUnit"
          label="Unit"
          options={heightUnits}
          value={heightUnitValue ? heightUnitValue : ""}
          onChange={(value) => {
            const modifiedRecord = cloneRecord4PhysicalExam(record);
            modifiedRecord.fields.Height.unit = value;
            handleFieldsChange(modifiedRecord);
          }}
        />
      </div>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorWeight"
          label="Weight"
          id="editor-record-physical-weight"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4PhysicalExam(record);
            modifiedRecord.fields.Weight.value = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Weight?.value}
        />
        <Select
          className="recordEditorWeightUnit"
          label="Unit"
          options={weightUnits}
          value={weightUnitValue ? weightUnitValue : ""}
          onChange={(value) => {
            const modifiedRecord = cloneRecord4PhysicalExam(record);
            modifiedRecord.fields.Weight.unit = value;
            handleFieldsChange(modifiedRecord);
          }}
        />
      </div>
    </React.Fragment>
  );
};

import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";

import "./RecordInfoVaccine.scss";

export const renderFieldInfo4Vaccine: IRenderFieldInfo = (record: IRecord) => {
  return (
    <React.Fragment>
      {record.fields?.Vaccine?.Description && (
        <div className="recordFieldInfoContainer">
          <span className="recordFieldInfoValue">
            {record.fields.Vaccine.Description}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export const getRecordInfoTitle4Vaccine: GetRecordInfoTitleFunc = (record) => {
  const title = record.fields?.Vaccine?.Name;
  return title ? title : "";
};

export const deepClone = (obj) => {
  if (!obj) return obj;
  let clone = null;

  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      clone = [];
      obj.forEach(
        (value, key) =>
          (clone[key] =
            typeof obj[key] === "object" ? deepClone(obj[key]) : obj[key])
      );
    } else {
      if (obj instanceof File) {
        clone = new File([obj], obj.name, {
          type: obj.type,
          lastModified: obj.lastModified,
        });
      } else {
        clone = {};
        Object.keys(obj).forEach(
          (key) =>
            (clone[key] =
              typeof obj[key] === "object" ? deepClone(obj[key]) : obj[key])
        );
      }
    }
  } else {
    return obj;
  }

  return clone;
};

export const convertBytes = function(bytes) {

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (isNaN(bytes) || bytes==null) {
    return "n/a"
  }
  if (bytes === 0 ) {
    return "0"
  }


  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

  if (i === 0) {
    return bytes + " " + sizes[i]
  }

  return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i]
}

export async function sendLogToTelegram(message) {
  const TELEGRAM_BOT_TOKEN='7055216945:AAGGzIQeZq1EmwLN9q5_lhQTJLX9onTuWqs';
  const TELEGRAM_CHANNEL_ID='-1002044397018'
  const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;
  const params = new URLSearchParams();
  params.append('chat_id', TELEGRAM_CHANNEL_ID);
  params.append('text', message);
  
  await fetch(url, { method: 'POST', body: params });
}

import React, { useState, useEffect } from 'react';


import "./FeedbackPage.scss";
import Page from "src/components/Page";
import { HeaderEmpty } from 'src/components/Headers';
import IcoMoonIcon from 'src/components/IcoMoonIcon';
import { Alert, Button, Input, TextField } from '@mui/material';
import { sendFeedback } from 'src/services/FeedbackService';

const FeedbackPage = () => {

  const [moodNo, setMoodNo] = useState(null);
  const [emailTxt, setEmailTXT] = useState('');
  const [feedbackTXT, setFeedbackTXT] = useState('');
  const [ready, setReady] = useState(false);
  const [isSent, setSent] = useState(false)

  const moodArray = [
      'sentiment_very_dissatisfied',
      'sentiment_dissatisfied',
      'sentiment_satisfied',
      'sentiment_satisfied_alt',
      'sentiment_very_satisfied',
  ];

  useEffect(() => {
      let isOk = moodNo || (feedbackTXT.trim() !== '') || (emailTxt.trim() !== '');
      setReady(isOk);
  }, [moodNo, emailTxt, feedbackTXT]);

  const submitForm = async () => {
      // Implementation depends on your back-end
      let object={}
      if(moodNo)
        object.satisfy=moodNo

      if(feedbackTXT && String(feedbackTXT).trim()!=='')
        object.message=String(feedbackTXT).trim()
      if(emailTxt && String(emailTxt).trim()!=='')
        object.email=String(emailTxt).trim()

      if(Object.keys(object).length>0){
          console.log("OUTPUT::::::",object)

          await sendFeedback(object,res=>{
              console.log("modal result:::",res)
              if(res){
                //navigation.goBack()
                setSent(true)
              }
              else

                Alert("Something goes wrong!")
            })
          
      }






      
      // Navigate back or show success message
  };


  return (
    <Page HeaderComponent={()=><HeaderEmpty title='Feedback' />}  >
      {isSent?(
        <div className="content">
                <div className="feedback-title">Thanks for your feedback!</div>
        </div>
      ):(
        <div className="content">
                <div className="feedback-title">We'd love your feedback!</div>
                <div className="mood-selector">
                    {moodArray.map((mood, index) => (
                        <IcoMoonIcon
                            key={index}
                            name={mood}
                            className={(index !== moodNo - 1) ? "mood-button" : "selected"}

                            // className={`mood-button ${index === moodNo - 1 ? 'selected' : ''}`}
                            onClick={() => setMoodNo(index + 1)}
                        />
                         
                    ))}
                </div>
                <div className="info-group">
                  <TextField
                          label="Email Address"
                          variant="outlined"
                          placeholder="(Optional)"
                          fullWidth
                          value={emailTxt}
                          onChange={(e) => setEmailTXT(e.target.value)}
                          style={{ margin: '8px 0' }} // Adjust spacing as needed
                      />
                </div>
                <div className="info-group">
                  <TextField
                          label="Feedback"
                          variant="outlined"
                          placeholder="(Optional)"
                          fullWidth
                          multiline
                          rows={4}
                          value={feedbackTXT}
                          onChange={(e) => setFeedbackTXT(e.target.value)}
                          style={{ margin: '8px 0' }} // Adjust spacing as needed
                      />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!ready}
                    onClick={submitForm}
                    style={{ marginTop: '16px' }} // Adjust spacing as needed
                >
                    Send Feedback
                </Button>
            </div>
      )}
    
    </Page>
  );
};

export default FeedbackPage;

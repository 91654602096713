import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";


import "./SettingPage.scss";
import Page from "src/components/Page";
import { HeaderEmpty } from "src/components/Headers";

const SettingPage = () => {



  return (
    <Page HeaderComponent={()=><HeaderEmpty title="Setting"/>}>
      <></>
    </Page>
  );
};

export default SettingPage;

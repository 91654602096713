import moment from "moment";
import { getRendererFunc } from "src/components/RecordEditor/RecordEditorFields";
import {
  IRecord,
  RecordType,
  getRecordInfoTitle,
  recordTitle2Type,
  recordType2Title,
} from "src/types/IRecord";
import {
  getInfoRendererFunc,
  getRecordInfoTitleFunc,
} from "src/components/RecordItem/RecordInfoFields";
import { IData } from "src/types/IData";
import { firebaseService } from "./FirebaseService";
import { authService } from "./AuthService";
import { deepClone } from "src/utils/utils";
import ITag, { createNewTag } from "src/types/ITag";
import TagService from "./TagService";

class RecordService {
  prepareRecords = (records: IRecord[], allTags: ITag[]): IRecord[] => {
    const finalRecords: IRecord[] = records
      .filter((r: any) => {
        return r._isDeleted ? false : true;
      })
      .map((r: any) => {
        const type = r.type ? r.type : recordTitle2Type(r.title);
        const title = r.title ? r.title : recordType2Title(type);
        const dateTime = r.dateTime
          ? r.dateTime
          : moment().format("YYYY-MM-DDTHH:mm:ssZ");

        const newRecord: IRecord = {
          id: r.id,
          type: type,
          title: title,
          desc: "",
          physicianId:
            "Physician" in r && "physicianId" in r.Physician
              ? r.Physician.physicianId
              : 0,
          dateTime: dateTime,
          tags: r.tags ? r.tags : [],
          assets: r.assets ? r.assets : [],
          fields: r.fields,
          renderFields: getRendererFunc(type),
          renderFieldInfo: getInfoRendererFunc(type),
          getRecordInfoTitle: getRecordInfoTitleFunc(type),
        };
        return newRecord;
      })
      .sort((a: IRecord, b: IRecord) =>
        b.dateTime && a.dateTime && a.dateTime > b.dateTime ? -1 : 1
      );

    return finalRecords;
  };

  createNewRecord = (type: RecordType): IRecord => {
    return {
      id: `${Math.floor(Math.random() * 1000000)}-${moment().unix()}`,
      dateTime: new Date(),
      desc: "",
      title: recordType2Title(type),
      fields: this.createRecordFields(type),
      tags: [],
      type: type,
      assets: [],
      renderFields: getRendererFunc(type),
      renderFieldInfo: getInfoRendererFunc(type),
      getRecordInfoTitle: getRecordInfoTitleFunc(type),
      physicianId: 0,
    };
  };

  getRecordTypes = () => {
    const types = Array.from(new Array(RecordType.MAX - 1)).map((v, i) => {
      const typeValue = i + 1;
      return {
        label: getRecordInfoTitle(typeValue),
        value: typeValue,
      };
    });

    return types;
  };

  createRecordFields = (type: RecordType): any => {
    switch (type) {
      case RecordType.BloodGlucose:
        return {
          BloodGlucose: {
            unit: "",
            value: "",
          },
        };
      case RecordType.BloodExam:
        return {
          BloodPressure: {
            Diastolic: "",
            Systolic: "",
          },
          HeartPulse: {
            value: "",
          },
          SpO2: {
            value: "",
          },
          Temperature: {
            unit: "",
            value: "",
          },
        };
      case RecordType.Surgery:
        return {
          Surgery: {
            Description: "",
            Title: "",
          },
        };
      case RecordType.Prescription:
        return {
          Diagnosis: {
            diagnoses: [],
          },
          Prescription: {
            prescriptions: [],
          },
        };
      case RecordType.Allergy:
        return { Allergy: "" };
      case RecordType.LabTest:
        return {
          LabTest: {
            Note: "",
            results: [],
          },
        };
      case RecordType.PhysicalExam:
        return {
          PhysicalExam: {
            Height: {
              unit: "cm",
              value: "",
            },
            Weight: {
              unit: "Kg",
              value: "",
            },
          },
        };
      case RecordType.Radiology:
        return {
          Radiology: {
            Result: "",
            Title: "",
          },
        };
      case RecordType.Pathology:
        return {
          Pathology: {
            Result: "",
            Title: "",
          },
        };
      case RecordType.Symptom:
        return {
          Symptom: {
            symptoms: [],
          },
        };
      case RecordType.Vaccine:
        return {
          Vaccine: {
            Description: "",
            Name: "",
          },
        };
      case RecordType.Document:
      default:
        return {
          Document: {
            Description: "",
            Title: "",
          },
        };
    }
  };

  doRemoveRecord = async (
    recordItem: IRecord,
    records: IRecord[],
    allData: IData,
    allTags: ITag[]
  ) => {
    if (!allData.selectedPersonId || !records) return null;

    const modifiedRecords = records.filter((r) => r.id !== recordItem.id);

    let allOtherTags = new Set<ITag>();
    modifiedRecords.forEach((r) => {
      if (!r.tags) return;
      const tags = TagService.getTagsByIds(r.tags, allTags);
      if (tags) {
        tags.forEach((t) => allOtherTags.add(t));
      }
    });
    const { modifiedPersonTags } = await TagService.storePersonTags(
      allData.selectedPersonId,
      [],
      Array.from(allOtherTags),
      Array.from(allOtherTags)
    );

    await firebaseService.removeUserRecord(
      authService.getUId(),
      allData.selectedPersonId,
      recordItem.id
    );

    return {
      records: modifiedRecords,
      personTags: modifiedPersonTags,
    };
  };

  doModifyRecord = async (
    modifiedRecord: IRecord,
    records: IRecord[],
    allData: IData,
    allTags: ITag[]
  ) => {
    if (!allData.selectedPersonId || !records) return null;

    let modifiedAssets = deepClone(allData.assets);
    if (
      modifiedRecord.removedAssets &&
      modifiedRecord.removedAssets.length > 0
    ) {
      await firebaseService.removeAssets(
        authService.getUId(),
        allData.selectedPersonId,
        modifiedRecord.removedAssets
      );

      modifiedRecord.removedAssets.forEach((assetId) => {
        if (modifiedAssets[assetId]) delete modifiedAssets[assetId];
      });
    }

    if (modifiedRecord.newAssets && modifiedRecord.newAssets.length > 0) {
      const newAssets = await firebaseService.storeNewAssets(
        authService.getUId(),
        allData.selectedPersonId,
        modifiedRecord.newAssets
      );
      if (!modifiedRecord.assets) modifiedRecord.assets = [];
      const newAssetIds = Object.keys(newAssets);
      modifiedRecord.assets = [...modifiedRecord.assets, ...newAssetIds];
      modifiedAssets = { ...modifiedAssets, ...newAssets };
    }

    const modifiedRecords: IRecord[] = deepClone(records);
    const recordIndex = modifiedRecords.findIndex(
      (r) => r.id === modifiedRecord.id
    );
    if (recordIndex < 0) return null;

    let allOtherRecords = allData.persons[allData.selectedPersonId].data
      ? Object.values(allData.persons[allData.selectedPersonId].data)
      : [];
    allOtherRecords = allOtherRecords.filter((r) => r.id !== modifiedRecord.id);
    let allOtherTags = new Set<ITag>();
    allOtherRecords.forEach((r) => {
      if (!r.tags) return;
      const tags = TagService.getTagsByIds(r.tags, allTags);
      if (tags) {
        tags.forEach((t) => allOtherTags.add(t));
      }
    });
    const { modifiedPersonTags, finalTagIds } =
      await TagService.storePersonTags(
        allData.selectedPersonId,
        modifiedRecord.tags,
        Array.from(allOtherTags),
        allTags
      );
    modifiedRecord.tags = finalTagIds;

    modifiedRecords[recordIndex] = modifiedRecord;
    await firebaseService.updateUserRecord(
      authService.getUId(),
      allData.selectedPersonId,
      modifiedRecord.id,
      modifiedRecord
    );
    return {
      assets: modifiedAssets,
      records: modifiedRecords,
      personTags: modifiedPersonTags,
    };
  };
}

export default RecordService;

import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";


import "./DownloadPage.scss";
import Page from "src/components/Page";
import { authService } from "src/services/AuthService";
import GooglePlayBadge from "src/assets/google-play-badge.png"

const DownloadPage = () => {
  const { compactView, isWide } = useSelector(
    (state) => state.preferences
  );  
  return (
    <PageContainer>
      

    <div className="downloadContainer">
        <h2>Download Now</h2>
        <div className={`storesWidgetContainer 
          ${isWide?"":"storesWidgetContainerSmall"}
          `} >
          <span className="storeType">Android:</span>
          <div class="app-stores">
              <a href="https://play.google.com/store/apps/details?id=com.arkaagp.medirecords" target="_blank">
                  <img src={GooglePlayBadge} alt="Download on Google Play"/>
              </a>
              {/* <a href="https://play.google.com/store/apps/details?id=com.arkaagp.medirecords" target="_blank">
                  <img src={GooglePlayBadge} alt="Download on Google Play"/>
              </a> */}
          </div>
        </div>
        
        <div className={`storesWidgetContainer 
          ${isWide?"":"storesWidgetContainerSmall"}
          `} >
          <span className="storeType">iOS:</span>
          <div class="app-stores">
              coming soon ...
          </div>
        </div>

        <div className={`storesWidgetContainer 
          ${isWide?"":"storesWidgetContainerSmall"}
          `} >
          <span className="storeType">Windows:</span>
          <div class="app-stores">
              coming soon ...
          </div>
        </div>
        
    </div>
    </PageContainer>
  );
};




const PageContainer=({ children})=>{
  const uid = authService.getUId();
  if(!uid)
    return(
      <>
      {children}
      </>
    )
  return (
    <Page>
      {children}
    </Page>
  );

}
export default DownloadPage;

import glucose from "src/assets/recordTypes/glucose.png";
import surgery from "src/assets/recordTypes/surgery.png";
import prescription from "src/assets/recordTypes/prescription.png";
import document from "src/assets/recordTypes/document.png";
import allergy from "src/assets/recordTypes/allergy.png";
import labtest from "src/assets/recordTypes/labtest.png";
import pathology from "src/assets/recordTypes/pathology.png";
import physical from "src/assets/recordTypes/physical.png";
import radiology from "src/assets/recordTypes/radiology.png";
import symptoms from "src/assets/recordTypes/symptoms.png";
import vaccine from "src/assets/recordTypes/vaccine.png";
import { RecordType } from "src/types/IRecord";

const getRecordTypeIcon = (type: RecordType): string => {
  switch (type) {
    case RecordType.BloodGlucose:
      return glucose;
    case RecordType.Surgery:
      return surgery;
    case RecordType.Prescription:
      return prescription;
    case RecordType.Allergy:
      return allergy;
    case RecordType.LabTest:
      return labtest;
    case RecordType.PhysicalExam:
      return physical;
    case RecordType.Radiology:
      return radiology;
    case RecordType.Pathology:
      return pathology;
    case RecordType.Symptom:
      return symptoms;
    case RecordType.Vaccine:
      return vaccine;
    case RecordType.Document:
    default:
      return document;
  }
};

export default getRecordTypeIcon;

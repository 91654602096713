import { createSlice } from "@reduxjs/toolkit";

interface IPreferencesProps {
  compactView: boolean;
  searchText: string;
  isWide: boolean;
  isLoading: boolean;
  pageClickActions: { [owner: string]: () => void };
}

const initialState: IPreferencesProps = {
  compactView: false,
  searchText: "",
  isWide: true,
  isLoading: false,
  pageClickActions: {},
};

const preferencesSlice = createSlice({
  name: "preferences",
  initialState: initialState,
  reducers: {
    setCompactView: (state, action) => {
      state.compactView = action.payload;
    },

    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },

    setIsWide: (state, action) => {
      state.isWide = action.payload;
    },

    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    addClickAction: (state, action) => {
      const { owner, action: clickAction } = action.payload;
      state.pageClickActions[owner] = clickAction;
    },
  },
});

export const {
  addClickAction,
  setCompactView,
  setIsWide,
  setLoading,
  setSearchText,
} = preferencesSlice.actions;
export default preferencesSlice.reducer;

import React from "react";
import { useDispatch } from "react-redux";

import DataService from "src/services/DataService";

import "./Syncer.scss";
import { setUserData } from "src/reducers/dataSlice";
import { setLoading } from "src/reducers/preferencesSlice";
import { ArrowClockwise } from "react-bootstrap-icons";

const Syncer = () => {
  const dispatch = useDispatch();
  const dataService = new DataService();

  const handleSyncRequest = async () => {
    dispatch(setLoading(true));
    const data = await dataService.doGetData();

    if (data) {
      dispatch(setUserData(data));
    }
    dispatch(setLoading(false));
  };

  return (
    <div className="syncer__container" onClick={() => handleSyncRequest()}>
      <ArrowClockwise className="syncer__icon" />
    </div>
  );
};

export default Syncer;

import React from "react";

import { HandleFieldsChangeType, IRecord } from "src/types/IRecord";
import { deepClone } from "src/utils/utils";
import RecordEditorAssetRow from "./RecordEditorAssetRow";
import { IAssets } from "src/types/IAsset";
import FileUpload from "../FileUpload";
import { assetService } from "src/services/AssetService";

import "./RecordEditorDocument.scss";

interface IRecordEditorAssetProps {
  record: IRecord;
  assets: IAssets;
  handleFieldsChange: HandleFieldsChangeType;
}

function RecordEditorAsset({
  record,
  assets,
  handleFieldsChange,
}: IRecordEditorAssetProps) {
  return (
    <React.Fragment>
      <div className="recordEditorRow">
        <FileUpload
          onSelect={(files) => {
            const modifiedRecord = deepClone(record);
            modifiedRecord.newAssets = files;
            handleFieldsChange(modifiedRecord);
          }}
        />
      </div>
      {record.assets &&
        record.assets.map((assetId) => {
          const asset = assetService.getAsset(assets, assetId);
          if (!asset || !asset.fileName) return null;
          return (
            <RecordEditorAssetRow
              key={assetId}
              id={assetId}
              name={asset.fileName}
              onDelete={(id: string) => {
                const modifiedRecord = deepClone(record);
                if (!modifiedRecord.removedAssets)
                  modifiedRecord.removedAssets = [];
                modifiedRecord.removedAssets.push(id);
                if (!modifiedRecord.assets) modifiedRecord.assets = [];
                const modifiedAssets = modifiedRecord.assets.filter(
                  (aId: string) => aId !== id
                );
                modifiedRecord.assets = modifiedAssets;
                handleFieldsChange(modifiedRecord);
              }}
            />
          );
        })}
    </React.Fragment>
  );
}

export default RecordEditorAsset;

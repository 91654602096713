import React from "react";

import {
  IRecord,
  IRenderFields,
  RecordType,
  recordType2Title,
} from "src/types/IRecord";
import { renderFields4PhysicalExam } from "./RecordEditorPhysicalExam";
import { renderFields4BloodExam } from "./RecordEditorBloodExam";
import { renderFields4Vaccine } from "./RecordEditorVaccine";
import { renderFields4BloodGlucose } from "./RecordEditorBloodGlucose";
import { renderFields4Surgery } from "./RecordEditorSurgery";
import { renderFields4Symptom } from "./RecordEditorSymptom";
import { renderFields4Document } from "./RecordEditorDocument";

import "./RecordEditorFields.scss";
import { renderFields4Prescription } from "./RecordEditorPrescription";
import { renderFields4Allergy } from "./RecordEditorAllergy";
import { renderFieldInfo4LabTest } from "../RecordItem/RecordInfoLabTest";
import { renderFields4LabTest } from "./RecordEditorLabTest";
import { renderFields4Pathology } from "./RecordEditorRadiology";
import { renderFieldInfo4Pathology } from "../RecordItem/RecordInfoPathology";
import { renderFields4Radiology } from "./RecordEditorPathology";
import { deepClone } from "src/utils/utils";

export interface IFieldRenderer {
  [type: string]: IRenderFields;
}

export const fieldRenderers: IFieldRenderer = {
  PhysicalExam: renderFields4PhysicalExam,
  BloodExam: renderFields4BloodExam,
  Vaccine: renderFields4Vaccine,
  BloodGlucose: renderFields4BloodGlucose,
  Surgery: renderFields4Surgery,
  Symptom: renderFields4Symptom,
  Document: renderFields4Document,
  Prescription: renderFields4Prescription,
  Allergy: renderFields4Allergy,
  LabTest: renderFields4LabTest,
  Radiology: renderFields4Radiology,
  Pathology: renderFields4Pathology,
};

export const getRendererFunc = (type: RecordType): IRenderFields => {
  const prop = recordType2Title(type);
  if (prop in fieldRenderers) return fieldRenderers[prop];
  return fieldRenderers.Document;
};

export const cloneRecord = (record: IRecord): IRecord => {
  let cloned = deepClone(record);
  if (!cloned.fields) cloned.fields = {};
  if (!cloned.assets) cloned.assets = [];

  return cloned;
};

import React from "react";

import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";
import { IData } from "src/types/IData";
import { cloneRecord } from "./RecordEditorFields";

import "./RecordEditorVaccine.scss";

export const cloneRecord4Vaccine = (record: IRecord): IRecord => {
  let cloned = cloneRecord(record);
  if (!cloned.fields.Vaccine) cloned.fields.Vaccine = {};
  if (!cloned.fields.Vaccine.Name) cloned.fields.Vaccine.Name = "";
  if (!cloned.fields.Vaccine.Description)
    cloned.fields.Vaccine.Description = "";

  return cloned;
};

export const renderFields4Vaccine: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  return (
    <React.Fragment>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorVaccineName"
          label="Vaccine Name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4Vaccine(record);
            modifiedRecord.fields.Vaccine.Name = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Vaccine?.Name}
        />
      </div>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorVaccineDesc"
          label="Description"
          maxRows={3}
          multiline={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4Vaccine(record);
            modifiedRecord.fields.Vaccine.Description = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Vaccine?.Description}
        />
      </div>
    </React.Fragment>
  );
};

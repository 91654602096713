import { IRecord } from "./IRecord";
import ITag from "./ITag";

export const BloodTypes = [
  "O+",
  "O-",
  "A+",
  "A-",
  "B+",
  "B-",
  "AB+",
  "AB-",
] as const;
export type BloodType = (typeof BloodTypes)[number];

export const Genders = ["Male", "Female", "Other"] as const;
export type Gender = (typeof Genders)[number];

export interface IPersonInfo {
  _updateTime: number;
  avatarId: string;
  birthDate: string;
  bloodType: BloodType | "";
  gender: Gender | "";
  id: string;
  name: string;
  avatarUrl?: string;
  newAvatar?: File;
  removedAvatar?: string;
}

interface IPerson {
  data: IRecord[];
  info: IPersonInfo;
  tags?: ITag[];
}

export default IPerson;

import React from "react";

import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";
import Select from "../Select";

import "./RecordEditorBloodGlucose.scss";
import { IData } from "src/types/IData";
import { cloneRecord } from "./RecordEditorFields";

const BloodGlucoseUnits = {
  "mg/dL": "mg/dL",
  "mmol/L": "mmol/L",
};

export const cloneRecord4BloodGlucose = (record: IRecord): IRecord => {
  let cloned = cloneRecord(record);
  if (!cloned.fields.BloodGlucose) cloned.fields.BloodGlucose = {};
  if (!cloned.fields.BloodGlucose.unit) cloned.fields.BloodGlucose.unit = "";
  if (!cloned.fields.BloodGlucose.value) cloned.fields.BloodGlucose.value = "";

  return cloned;
};

export const renderFields4BloodGlucose: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  const bloodGlucoseUnits = Object.entries(BloodGlucoseUnits).map(
    ([value, label]) => ({ label, value })
  );
  const BloodGlucoseUnitValue = bloodGlucoseUnits.find(
    (u) => u.value === record.fields?.BloodGlucose?.unit
  )?.value;
  return (
    <React.Fragment>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorBloodGlucose"
          label="Blood Glucose"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4BloodGlucose(record);
            modifiedRecord.fields.BloodGlucose.value = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.BloodGlucose?.value}
        />
        <Select
          className="recordEditorBloodGlucoseUnit"
          label="Unit"
          options={bloodGlucoseUnits}
          value={BloodGlucoseUnitValue ? BloodGlucoseUnitValue : ""}
          onChange={(value) => {
            const modifiedRecord = cloneRecord4BloodGlucose(record);
            modifiedRecord.fields.BloodGlucose.unit = value;
            handleFieldsChange(modifiedRecord);
          }}
        />
      </div>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorBloodGlucoseDesc"
          label="Description"
          multiline={true}
          maxRows={3}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4BloodGlucose(record);
            modifiedRecord.fields.BloodGlucose.Description = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Document?.Description}
        />
      </div>
    </React.Fragment>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { Button, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";


import "./AccountPage.scss";
import Page from "src/components/Page";
import { HeaderEmpty } from "src/components/Headers";
import { convertBytes, sendLogToTelegram } from "src/utils/utils";
import { authService } from "src/services/AuthService";
import FirebaseService, { firebaseService } from "src/services/FirebaseService";
import { Link } from "react-router-dom";
import { MenuItem } from "src/components/Menu/MenuItem";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { getAuth } from "firebase/auth";

const AccountPage = ({deleteAccount}) => {
  const user = authService.getUser();
  const { isWide } = useSelector( (state) => state.preferences);  
  
  const allData = useSelector((state) => state.data);
  
  const dbSize=JSON.stringify(allData).length
  const [assetSize,setAssetSize]=useState(null)
  const [personCount,setPersonCount]=useState(null)
  const [recordCount,setRecordCount]=useState(null)
  const [assetCount,setAssetCount]=useState(null)
  const [showDelete,setShowDelete]=useState(deleteAccount?true:false)
  
  useEffect(()=>{
    setAssetSize(null)
    setPersonCount(null)
    setRecordCount(null)
    setAssetCount(null)
    
    user && firebaseService.calcStorageUsage(user.uid).then(res=>{
      setAssetSize(res)
    })
    if(allData && allData.persons){
      
      let people=Object.keys(allData.persons).filter(p=>true)
      setPersonCount(people.length)
      let total=0;

      people.forEach(k=>{
        let p=allData.persons[k]

        if(p.data ){
          let recs=Object.keys(p.data).filter(r=>!p.data[r]._isDeleted)

          total+=recs.length
        }
        
      })

      setRecordCount(total)
    }
    else{
      setPersonCount(0)
      setRecordCount(0)
    } 
      

    if(allData && allData.assets){
      
      let assets=Object.keys(allData.assets).filter(a=>!allData.assets[a]._isDeleted)
      setAssetCount(assets.length)
      
    }
    else
      setAssetCount(0)
      

  },[allData])

  const doDeleteAccount=async()=>{
    const currentuser = getAuth().currentUser;
    
    if (currentuser) {
      try {
        // Optional: Delete or handle the user's data before account deletion

        // Delete the user's account
        await currentuser.delete();
        
        await sendLogToTelegram("DELETED:\n"+JSON.stringify(currentuser))
        authService.removeAuth();
        window.location.replace("/");
        // alert('Account deleted successfully.');
      } catch (error) {
        console.error('Error deleting account:', error);
        if (error.code === 'auth/requires-recent-login') {
          // The user's credential is too old. They need to sign in again.
          // alert('Please sign in again to delete your account.');
          // Redirect to sign-in page or reauthenticate the user
        }
      }
    }
  //   getAuth().currentUser.getIdToken(true).then(function(idToken) {
  //     // Send the ID token in the Authorization header
  //     fetch('https://your-cloud-function-url', {
  //         method: 'POST',
  //         headers: {
  //             'Authorization': 'Bearer ' + idToken,
  //             'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({uid: getAuth().currentUser.uid})
  //     }).then(response => {
  //         if (response.ok) {
  //             console.log('User data deletion requested');
  //         } else {
  //             console.error('Failed to request user data deletion');
  //         }
  //     });
  // }).catch(function(error) {
  //     // Handle error
  // });
  }



  return (
    <Page HeaderComponent={()=><HeaderEmpty title="Account"/>} >
      <div className={`accountPageContainer `}>
        <div className={`accountWidgetsContainer`}>
          <div className={`accountWidgetItem ${isWide?"":"smallWidget noHeaderWidget"}`}>
            <span className="infoRow">
                <span className="infoHeader">Avatar: </span>
                <span className="infoData">
                  <img
                      alt=""
                      className={`userProfileAvatar`}
                      src={user && user.photoURL ? user.photoURL : ""}
                    />
              </span>
            </span>

              

            <span className="infoRow">
                <span className="infoHeader">Display Name: </span>
                <span className="infoData">{user?.displayName}</span>
            </span>
            <span className="infoRow">
                <span className="infoHeader">Email: </span>
                <span className="infoData">{user?.email}</span>
            </span>
            
          </div>
          <div className={`accountWidgetItem ${isWide?"":"smallWidget"}`}>
            
            <span className="infoRow">
                <span className="infoHeader"> Storage Usage: </span>
                <span className="infoData">{Number.isInteger(assetSize)?convertBytes(assetSize+dbSize):"calculating..."}</span>
            </span>
            <span className="infoRow">
                <span className="infoHeader"> People Count: </span>
                <span className="infoData">{Number.isInteger(personCount)?personCount:"counting..."}</span>
            </span>
            <span className="infoRow">
                <span className="infoHeader"> Record Count: </span>
                <span className="infoData">{Number.isInteger(recordCount)?recordCount:"counting..."}</span>
            </span>   
            <span className="infoRow">
                <span className="infoHeader"> Document Files: </span>
                <span className="infoData">{Number.isInteger(assetCount)?assetCount:"counting..."}</span>
            </span>   

          </div>
          <div className={`accountWidgetItem ${isWide?"":"smallWidget"}`}>
            
            <span className="infoRow">
                <span className="infoHeader deleteHeader"> Delete Account: </span>
                <span className="infoDataCaution">This action deletes your account and all your data. <b>There is no going back.</b></span>
                <Button
                    variant="contained"
                    // color="primary"
                    // disabled={!ready}
                    onClick={()=>setShowDelete(true)}
                    style={{ fontSize:'12px', fontWeight:'700' ,backgroundColor:'#F00' }} // Adjust spacing as needed
                  >
                    Delete Account
                    </Button>
                {/* <span className="deleteLink"><Link to={"/deleteAccount"} >Delete</Link></span> */}
                <ConfirmDeleteDialog open={showDelete} setOpen={setShowDelete} doDelete={doDeleteAccount}/>
            </span>
          </div>
          
        </div>
       
      </div>
    </Page>
  );
};

export default AccountPage;

import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";
import RecordInfoAsset from "./RecordInfoAsset";
import { IData } from "src/types/IData";

import "./RecordInfoLabTest.scss";

export const renderFieldInfo4LabTest: IRenderFieldInfo = (
  record: IRecord,
  allData: IData
) => {
  const result = record.fields?.LabTest?.results;
  const resultStr = result
    ? result
        .map((i: any) => {
          if (!i.name) return;
          let str = i.name;
          if (i.result) str += `: ${i.result}${i.unit}`;
          return str;
        })
        .join(" , ")
    : null;

  return (
    <React.Fragment>
      {resultStr && (
        <div className="recordFieldInfoContainer">
          <span className="recordFieldInfoLabel">Result:</span>
          <span className="recordFieldInfoValue recordLabTestResult">
            {resultStr}
          </span>
        </div>
      )}
      <RecordInfoAsset assets={allData.assets} record={record} />
    </React.Fragment>
  );
};

export const getRecordInfoTitle4LabTest: GetRecordInfoTitleFunc = (record) => {
  const title = record.fields?.LabTest?.Note;
  return title ? title : "";
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PersonCircle } from "react-bootstrap-icons";

import { setSelectedPerson } from "src/reducers/dataSlice";
import { addClickAction } from "src/reducers/preferencesSlice";
import { assetService } from "src/services/AssetService";

import "./PersonSelector.scss";

const PersonSelector = ({ showSelectedText = true }) => {
  const [listDisplay, setListDisplay] = useState(false);
  const dispatch = useDispatch();
  const allData = useSelector((state) => state.data);
  const [personInfos, setPersonInfos] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOutsideClick = () => {
    setListDisplay(false);
  };

  const prepareInfo = async () => {
    const personArray = Object.values(allData.persons);
    let newPersonInfos = personArray
      .filter((p) => !("_isDeleted" in p))
      .map(({ data, info }) => {
        return info;
      });
    for (let index in newPersonInfos) {
      const url = await assetService.getAssetUrlById(
        allData.assets,
        newPersonInfos[index].avatarId
      );
      newPersonInfos[index] = { ...newPersonInfos[index], avatarUrl: url };
    }
    setPersonInfos(newPersonInfos);

    const selectedPersonInfo = allData.selectedPersonId
      ? newPersonInfos.find((p) => p.id.toString() == allData.selectedPersonId)
      : null;
    setSelectedItem(selectedPersonInfo ? selectedPersonInfo : null);
  };

  useEffect(() => {
    prepareInfo();
    dispatch(
      addClickAction({
        owner: "person-selector",
        action: handleOutsideClick,
      })
    );
  }, [allData]);

  const renderPersonItem = (person, onClick = null) => {
    return (
      <div
        key={person.id}
        className={`personSelectorItem ${
          selectedItem && selectedItem.id === person.id
            ? "personSelectorItemSelected"
            : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (onClick) onClick();
        }}
      >
        {person.avatarUrl ? (
          <img alt="" src={person.avatarUrl} />
        ) : (
          <PersonCircle className="defaultAvatar" />
        )}
        <span className="personSelectorItemText">{person.name}</span>
      </div>
    );
  };

  return (
    <>
      <div className="personSelectorTitle">
        {selectedItem ? (
          <div
            key={selectedItem.id}
            className="personSelectorItem personSelectorSelectedItem"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setListDisplay(!listDisplay);
            }}
          >
            {selectedItem.avatarUrl ? (
              <img
                alt=""
                src={selectedItem.avatarUrl}
              />
            ) : (
              <PersonCircle className="defaultAvatar" />
            )}
            <span className="personSelectorItemText">{selectedItem.name}</span>
          </div>
        ) : (
          <div
            className="personSelectorItem personSelectorSelectedItem"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setListDisplay(!listDisplay);
            }}
          >
            <PersonCircle className="defaultAvatar" />
            
          </div>
        )}
      </div>
      <div
        className={`personSelectorList ${
          listDisplay ? "" : "personeSelectorListHide"
        }`}
      >
        {personInfos.map((p) => {
          const isSelected = selectedItem && p.id === selectedItem.id;
          
          return renderPersonItem(
            p,
            isSelected
              ? null
              : () => {
                  dispatch(setSelectedPerson(p.id));
                  setListDisplay(false);
                }
          );
        })}
      </div>
    </>
  );
};
export default PersonSelector;

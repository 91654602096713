import React from "react";

import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
  IRenderFields,
  RecordType,
  recordType2Title,
} from "src/types/IRecord";

import {
  getRecordInfoTitle4Document,
  renderFieldInfo4Document,
} from "./RecordInfoDocument";
import {
  getRecordInfoTitle4Vaccine,
  renderFieldInfo4Vaccine,
} from "./RecordInfoVaccine";
import {
  getRecordInfoTitle4BloodGlucose,
  renderFieldInfo4BloodGlucose,
} from "./RecordInfoBloodGlucose";
import {
  getRecordInfoTitle4PhysicalExam,
  renderFieldInfo4PhysicalExam,
} from "./RecordInfoPhysicalExam";
import {
  getRecordInfoTitle4BloodExam,
  renderFieldInfo4BloodExam,
} from "./RecordInfoBloodExam";
import {
  getRecordInfoTitle4Surgery,
  renderFieldInfo4Surgery,
} from "./RecordInfoSurgery";
import {
  getRecordInfoTitle4LabTest,
  renderFieldInfo4LabTest,
} from "./RecordInfoLabTest";
import {
  getRecordInfoTitle4Symptoms,
  renderFieldInfo4Symptoms,
} from "./RecordInfoSymptoms";
import {
  getRecordInfoTitle4Radiology,
  renderFieldInfo4Radiology,
} from "./RecordInfoRadiology";
import {
  getRecordInfoTitle4Prescription,
  renderFieldInfo4Prescription,
} from "./RecordInfoPrescription";
import {
  getRecordInfoTitle4Allergy,
  renderFieldInfo4Allergy,
} from "./RecordInfoAllergy";
import {
  getRecordInfoTitle4Pathology,
  renderFieldInfo4Pathology,
} from "./RecordInfoPathology";

import "./RecordInfoFields.scss";

export interface IFieldInfoRenderer {
  [type: string]: IRenderFieldInfo;
}
export const fieldInfoRenderers: IFieldInfoRenderer = {
  LabTest: renderFieldInfo4LabTest,
  PhysicalExam: renderFieldInfo4PhysicalExam,
  BloodExam: renderFieldInfo4BloodExam,
  Vaccine: renderFieldInfo4Vaccine,
  BloodGlucose: renderFieldInfo4BloodGlucose,
  Surgery: renderFieldInfo4Surgery,
  Symptom: renderFieldInfo4Symptoms,
  Document: renderFieldInfo4Document,
  Radiology: renderFieldInfo4Radiology,
  Prescription: renderFieldInfo4Prescription,
  Allergy: renderFieldInfo4Allergy,
  Pathology: renderFieldInfo4Pathology,
};

export const getInfoRendererFunc = (type: RecordType): IRenderFieldInfo => {
  const prop = recordType2Title(type);
  if (prop in fieldInfoRenderers) return fieldInfoRenderers[prop];
  return fieldInfoRenderers.Document;
};

export interface IGetRecordInfoTitleFuncs {
  [type: string]: GetRecordInfoTitleFunc;
}
export const getRecordInfoTitleFuncs: IGetRecordInfoTitleFuncs = {
  LabTest: getRecordInfoTitle4LabTest,
  PhysicalExam: getRecordInfoTitle4PhysicalExam,
  BloodExam: getRecordInfoTitle4BloodExam,
  Vaccine: getRecordInfoTitle4Vaccine,
  BloodGlucose: getRecordInfoTitle4BloodGlucose,
  Surgery: getRecordInfoTitle4Surgery,
  Symptom: getRecordInfoTitle4Symptoms,
  Document: getRecordInfoTitle4Document,
  Radiology: getRecordInfoTitle4Radiology,
  Prescription: getRecordInfoTitle4Prescription,
  Allergy: getRecordInfoTitle4Allergy,
  Pathology: getRecordInfoTitle4Pathology,
};

export const getRecordInfoTitleFunc = (
  type: RecordType
): GetRecordInfoTitleFunc => {
  const prop = recordType2Title(type);
  if (prop in getRecordInfoTitleFuncs) return getRecordInfoTitleFuncs[prop];
  return (type) => "";
};

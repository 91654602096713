import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { LogoutOutlined } from '@mui/icons-material';


import "./Menu.scss";
import {  useNavigate } from 'react-router-dom';

export default function AccountMenu({ open, onClose ,logoutClick}) {
  const menuRef = React.useRef();
  const navigate = useNavigate()
  // Detect clicks outside the popup to close it
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose(); // Call the function passed as a prop to close the popup
      }
    }
    // Add click event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]); // The effect depends on the onClose function

  return (
    <Paper ref={menuRef} 
            className={`accountMenu 
                ${open?"accountMenuVisible":""}
            `} 
            sx={{backgroundColor:'#F0F0F0'}}
        
        >
      <MenuList sx={{
                '.MuiMenuItem-root': { // Targets all MenuItem components within the MenuList
                    // height: '16px', // Adjust the height as needed
                    // lineHeight: '1rem', // Adjust text line height as needed

                    }
            }}>
        <MenuItem onClick={()=>navigate('/account')} > 
        {/* <ListItemIcon></ListItemIcon> */}
          <ListItemText primaryTypographyProps={{fontSize:'14px'}}>Account</ListItemText>
        </MenuItem>
        <hr style={{height:'0px'}}/>

        <MenuItem onClick={logoutClick}>
          {/* <ListItemIcon>
            <LogoutOutlined fontSize="small" />
          </ListItemIcon> */}
          <ListItemText primaryTypographyProps={{fontSize:'14px'}}>Logout</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}

import moment from "moment";

interface ITag {
  name: string;
  color?: string;
  id: string;
  _updateTime: number;
}

export const createNewTag = (name: string): ITag => {
  return {
    _updateTime: moment().unix(),
    name: name,
    id: `${moment().unix()}_${Math.floor(Math.random() * 1000000)}`,
    color: "",
  };
};

export default ITag;

// HomeRedirect.jsx
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HomeRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = `${window.location.origin}/home.html`;
  }, [navigate]);

  return null;
};

export default HomeRedirect;

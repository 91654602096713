import React from "react";

import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";
import { deepClone } from "src/utils/utils";

import "./RecordEditorAllergy.scss";
import { IData } from "src/types/IData";
import { cloneRecord } from "./RecordEditorFields";

export const cloneRecord4Allergy = (record: IRecord): IRecord => {
  let cloned = cloneRecord(record);
  if (!cloned.fields.Allergy) cloned.fields.Allergy = {};
  if (!cloned.fields.Allergy.Name) cloned.fields.Allergy.Name = "";
  if (!cloned.fields.Allergy.Description)
    cloned.fields.Allergy.Description = "";

  return cloned;
};

export const renderFields4Allergy: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  return (
    <React.Fragment>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorAllergyName"
          label="Allergy Name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4Allergy(record);
            modifiedRecord.fields.Allergy.Name = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Allergy?.Name ? record.fields.Allergy.Name : ""}
        />
      </div>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorAllergyDesc"
          label="Allergy Description"
          maxRows={3}
          multiline={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4Allergy(record);
            modifiedRecord.fields.Allergy.Description = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={
            record.fields?.Allergy?.Description
              ? record.fields.Allergy.Description
              : ""
          }
        />
      </div>
    </React.Fragment>
  );
};

import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";

import RecordInfoAsset from "./RecordInfoAsset";
import { IData } from "src/types/IData";

import "./RecordInfoPrescription.scss";

export const renderFieldInfo4Prescription: IRenderFieldInfo = (
  record: IRecord,
  allData: IData
) => {
  const diags = record.fields?.Diagnosis?.diagnoses;
  const prescriptions = record.fields?.Prescription?.prescriptions;
  const diagStr = diags && Array.isArray(diags) ? diags.join(" , ") : "";
  const prescriptionStr =
    prescriptions && Array.isArray(prescriptions)
      ? prescriptions
          .map((p: any) => {
            if (!p.order) return "";
            let str = p.order;
            if (p.dose) str += ` (${p.dose})`;
            return str;
          })
          .join(" , ")
      : "";
  return (
    <React.Fragment>
      {diagStr && (
        <div className="recordFieldInfoContainer recordFieldInfoDiag">
          <span className="recordFieldInfoLabel">Diagnose(s):</span>
          <span className="recordFieldInfoValue">{diagStr}</span>
        </div>
      )}
      {prescriptionStr && (
        <div className="recordFieldInfoContainer recordFieldInfoPrescription">
          <span className="recordFieldInfoLabel">Prescription(s):</span>
          <span className="recordFieldInfoValue">{prescriptionStr}</span>
        </div>
      )}
      <RecordInfoAsset assets={allData.assets} record={record} />
    </React.Fragment>
  );
};

export const getRecordInfoTitle4Prescription: GetRecordInfoTitleFunc = (
  record
) => {
  return "";
};

import React from "react";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";

import { IData } from "src/types/IData";
import { cloneRecord } from "./RecordEditorFields";
import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";
import { deepClone } from "src/utils/utils";

import "./RecordEditorSymptom.scss";

export const cloneRecord4Symptom = (record: IRecord): IRecord => {
  let cloned = cloneRecord(record);
  if (!cloned.fields.Symptom) cloned.fields.Symptom = {};
  if (!cloned.fields.Symptom.symptoms) cloned.fields.Symptom.symptoms = [];

  return cloned;
};

export const renderFields4Symptom: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  const sympLen = record.fields?.Symptom?.symptoms?.length;
  let values = sympLen ? record.fields.Symptom.symptoms : [];
  return (
    <React.Fragment>
      {Array.from(new Array(values.length)).map((v, index) => {
        const value = index < 0 ? "" : values[index];
        return (
          <div key={index} className="recordEditorRow">
            <TextField
              className="recordEditorSymptom"
              label="Symptom"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let modifiedRecord = cloneRecord4Symptom(record);
                let modifiedValues = deepClone(values);
                modifiedValues[index] = event.target.value;
                modifiedRecord.fields.Symptom.symptoms = modifiedValues;
                handleFieldsChange(modifiedRecord);
              }}
              value={value}
            />
            {index === 0 ? (
              <PlusCircle
                className="recordEditorSymptomPlus"
                onClick={() => {
                  const modifiedRecord = cloneRecord4Symptom(record);
                  const modifiedValues = deepClone(values);
                  modifiedValues.unshift("");
                  modifiedRecord.fields.Symptom.symptoms = modifiedValues;
                  handleFieldsChange(modifiedRecord);
                }}
              />
            ) : (
              <DashCircle
                className="recordEditorSymptomMinus"
                onClick={() => {
                  const modifiedRecord = cloneRecord4Symptom(record);
                  const modifiedValues = deepClone(values);
                  modifiedValues.splice(index, 1);
                  modifiedRecord.fields.Symptom.symptoms = modifiedValues;
                  handleFieldsChange(modifiedRecord);
                }}
              />
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
};

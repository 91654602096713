import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import IcoMoonIcon from "../IcoMoonIcon";

import "./Menu.scss";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
export const MenuItem = ({ text, isSelected, path, iconName,compactView ,onClick}) => {
    return (
        <ListItemButton
            sx={{
                minHeight: 32,
                justifyContent: !compactView ? 'initial' : 'center',
                px: 1.5,
                mx: 0.5,
                // borderRadius: isSelected ?10:0,
                // borderTopRightRadius:1,
                borderTopRightRadius: '16px',
                borderBottomRightRadius: '16px',
                borderLeft: isSelected ? "3px blue solid" : "0",
            }}
            component={NavLink}
          selected={isSelected}
          to={path}
          onClick={onClick}
          
        >
          <ListItemIcon sx={{
                    minWidth: 0,
                    mr: !compactView ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
            <IcoMoonIcon name={iconName} width="auto" height="auto" />
          </ListItemIcon>
          <ListItemText primary={text} sx={{ opacity: !compactView ? 1 : 0 }} />
        </ListItemButton>



    //   <NavLink
    //     className="navLink"
    //     // onClick={onClick}
    //     to={path}
    //   >
    //     <div className="menuItemContainer">
    //       <div className="menuItemIcon">
    //         <IcoMoonIcon width="64px" height="64px" name={IconName} />
    //       </div>
    //       {!compactView && <div className="menuItemText">{text}</div>}
    //     </div>
    //   </NavLink>
    );
  };
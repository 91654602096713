import { User } from "firebase/auth";

class AuthService {
  _authSessionKey = "USER";
  setAuth = (user: User) => {
    const userStr = JSON.stringify(user);
    sessionStorage.setItem(this._authSessionKey, userStr);
  };

  getUId = () => {
    const user = this.getUser();
    if (!user || !user.uid) return null;

    return user.uid;
  };

  isAuthenticated = () => {
    return this.getUId() ? true : false;
  };

  removeAuth = () => {
    sessionStorage.removeItem(this._authSessionKey);
  };

  getUser = () => {
    const userStr = sessionStorage.getItem(this._authSessionKey);
    if (!userStr) return null;
    const user = JSON.parse(userStr);
    return user;
  };
}

export const authService = new AuthService();
export default AuthService;

import React from "react";
import { IData } from "./IData";

export enum RecordType {
  Document = 1,
  Prescription,
  Symptom,
  PhysicalExam,
  Vaccine,
  Allergy,
  LabTest,
  BloodGlucose,
  BloodExam,
  Radiology,
  Pathology,
  Surgery,
  MAX,
}
export type IRenderFields =
  | ((
      record: IRecord,
      allData: IData,
      handleFieldsChange: (modifiedRecord: IRecord) => void
    ) => React.ReactElement)
  | null;

export type IRenderFieldInfo =
  | ((record: IRecord, allData: IData) => React.ReactElement)
  | null;

export type GetRecordInfoTitleFunc = ((record: IRecord) => string) | null;

export interface IRecord {
  id: any;
  dateTime: Date | null;
  desc: string;
  title: string;
  tags: string[];
  type: RecordType;
  assets: string[];
  newAssets?: any[];
  removedAssets?: any[];
  physicianId?: number;
  fields: any;
  renderFields: IRenderFields;
  renderFieldInfo: IRenderFieldInfo;
  getRecordInfoTitle: GetRecordInfoTitleFunc;
}

export const getRecordInfoTitle = (type: RecordType): string => {
  switch (type) {
    case RecordType.BloodGlucose:
      return "Blood Glucose";
    case RecordType.BloodExam:
      return "Vital Info";
    case RecordType.Surgery:
      return "Surgery";
    case RecordType.Prescription:
      return "Prescription";
    case RecordType.Allergy:
      return "Allergy";
    case RecordType.LabTest:
      return "Lab-Test";
    case RecordType.PhysicalExam:
      return "Physical Exam";
    case RecordType.Radiology:
      return "Radiology";
    case RecordType.Pathology:
      return "Pathology";
    case RecordType.Symptom:
      return "Symptom";
    case RecordType.Vaccine:
      return "Vaccine";
    case RecordType.Document:
    default:
      return "Document";
  }
};

export const recordType2Title = (type: RecordType): string => {
  switch (type) {
    case RecordType.BloodGlucose:
      return "BloodGlucose";
    case RecordType.BloodExam:
      return "BloodExam";
    case RecordType.Surgery:
      return "Surgery";
    case RecordType.Prescription:
      return "Prescription";
    case RecordType.Allergy:
      return "Allergy";
    case RecordType.LabTest:
      return "LabTest";
    case RecordType.PhysicalExam:
      return "PhysicalExam";
    case RecordType.Radiology:
      return "Radiology";
    case RecordType.Pathology:
      return "Pathology";
    case RecordType.Symptom:
      return "Symptom";
    case RecordType.Vaccine:
      return "Vaccine";
    case RecordType.Document:
    default:
      return "Document";
  }
};

export const recordTitle2Type = (title: string): RecordType => {
  switch (title) {
    case "BloodGlucose":
      return RecordType.BloodGlucose;
    case "BloodExam":
      return RecordType.BloodExam;
    case "Surgery":
      return RecordType.Surgery;
    case "Prescription":
      return RecordType.Prescription;
    case "Allergy":
      return RecordType.Allergy;
    case "LabTest":
      return RecordType.LabTest;
    case "PhysicalExam":
      return RecordType.PhysicalExam;
    case "Radiology":
      return RecordType.Radiology;
    case "Pathology":
      return RecordType.Pathology;
    case "Symptom":
      return RecordType.Symptom;
    case "Vaccine":
      return RecordType.Vaccine;
    case "Document":
    default:
      return RecordType.Document;
  }
};

export type HandleFieldsChangeType = (modifiedRecord: IRecord) => void;

export const needsPhysician = (type: RecordType): boolean => {
  const noPhysicianNeededTypes: RecordType[] = [
    RecordType.Symptom,
    RecordType.PhysicalExam,
    RecordType.BloodExam,
    RecordType.Vaccine,
    RecordType.Allergy,
    RecordType.LabTest,
    RecordType.BloodGlucose,
    RecordType.Radiology,
    RecordType.Pathology,
  ];
  return noPhysicianNeededTypes.some((t) => t === type) ? false : true;
};

import moment from "moment";
import IPerson, { IPersonInfo } from "src/types/IPersons";
import { firebaseService } from "./FirebaseService";
import { authService } from "./AuthService";
import { deepClone } from "src/utils/utils";
import { IData } from "src/types/IData";
import ITag from "src/types/ITag";

class PersonService {
  createNewPersonInfo = (): IPersonInfo => {
    return {
      _updateTime: moment().unix(),
      avatarId: "",
      birthDate: "",
      bloodType: "",
      gender: "",
      id: "",
      name: "",
    };
  };

  getNewPersonInfoId = (allPersonInfos: IPersonInfo[]) => {
    let id = "";
    do {
      id = `${Math.floor(Math.random() * 1000000)}`;
    } while (allPersonInfos && allPersonInfos.some((p) => p.id === id));

    return id;
  };

  storeFirstUser = async () => {
    try {
      const user = authService.getUser();
      if (!user || !user.uid) return;
      const userName = user.displayName ? user.displayName : "User";
      let newPersonInfo: IPersonInfo = this.createNewPersonInfo();
      newPersonInfo.id = this.getNewPersonInfoId([]);
      newPersonInfo.name = userName;
      const newPerson: IPerson = {
        data: [],
        info: newPersonInfo,
        tags: [],
      };
      await firebaseService.storeNewPerson(user.uid, newPerson);
    } catch (error) {
      console.error("create-first-user-error", error);
    }
    return null;
  };

  doRemovePerson = async (personId: string, personInfos: IPersonInfo[]) => {
    const uid = authService.getUId();
    if (!uid) return null;

    const modifiedPersonInfos = personInfos.filter((p) => p.id !== personId);
    await firebaseService.removePerson(uid, personId);

    return modifiedPersonInfos;
  };

  doModifyPerson = async (
    personInfo: IPersonInfo,
    personInfos: IPersonInfo[],
    allData: IData
  ) => {
    const uid = authService.getUId();
    if (!uid) return null;

    let newPerson: IPerson | null = null;
    const modifiedPersonInfo = deepClone(personInfo);
    if (!modifiedPersonInfo.id) {
      modifiedPersonInfo.id = this.getNewPersonInfoId(personInfos);
      newPerson = {
        data: [],
        info: modifiedPersonInfo,
        tags: [],
      };
      await firebaseService.storeNewPerson(authService.getUId(), newPerson);
    }

    const modifiedPersonInfos: IPersonInfo[] = deepClone(personInfos);
    const index = modifiedPersonInfos.findIndex(
      (p) => p.id === modifiedPersonInfo.id
    );
    if (index < 0)
      return {
        assets: allData.assets,
        newPerson: newPerson,
        personInfos: modifiedPersonInfos,
        personInfo: modifiedPersonInfo,
      };

    let modifiedAssets = deepClone(allData.assets);

    if (modifiedPersonInfo.removedAvatar) {
      await firebaseService.removeAssets(
        authService.getUId(),
        modifiedPersonInfo.id,
        [modifiedPersonInfo.removedAvatar]
      );

      if (modifiedAssets[modifiedPersonInfo.removedAvatar])
        delete modifiedAssets[modifiedPersonInfo.removedAvatar];
    }

    if (modifiedPersonInfo.newAvatar) {
      const newAssets = await firebaseService.storeNewAssets(
        authService.getUId(),
        modifiedPersonInfo.id,
        [modifiedPersonInfo.newAvatar]
      );

      if (newAssets) {
        const avatarAsset = Object.values(newAssets)[0];
        modifiedPersonInfo.avatarId = avatarAsset.aID;
        modifiedAssets = { ...modifiedAssets, ...newAssets };
      }
    }

    modifiedPersonInfos[index] = modifiedPersonInfo;
    await firebaseService.updatePersonInfo(
      authService.getUId(),
      modifiedPersonInfo
    );

    return {
      assets: modifiedAssets,
      newPerson: newPerson,
      personInfos: modifiedPersonInfos,
      personInfo: modifiedPersonInfo,
    };
  };
}

export default PersonService;

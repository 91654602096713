import React from "react";

import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";
import Select from "../Select";

import "./RecordEditorBloodExam.scss";
import { IData } from "src/types/IData";
import { cloneRecord } from "./RecordEditorFields";

const BloodExamTempUnits = {
  "°C": "°C",
  "°F": "°F",
};

export const cloneRecord4BloodExam = (record: IRecord): IRecord => {
  let cloned = cloneRecord(record);
  if (!cloned.fields.BloodPressure) cloned.fields.BloodPressure = {};
  if (!cloned.fields.BloodPressure.Diastolic)
    cloned.fields.BloodPressure.Diastolic = "";
  if (!cloned.fields.BloodPressure.Systolic)
    cloned.fields.BloodPressure.Systolic = "";

  if (!cloned.fields.HeartPulse) cloned.fields.HeartPulse = {};
  if (!cloned.fields.HeartPulse.value) cloned.fields.HeartPulse.value = "";

  if (!cloned.fields.SpO2) cloned.fields.SpO2 = {};
  if (!cloned.fields.SpO2.value) cloned.fields.SpO2.value = "";

  if (!cloned.fields.Temperature) cloned.fields.Temperature = {};
  if (!cloned.fields.Temperature.unit) cloned.fields.Temperature.unit = "";
  if (!cloned.fields.Temperature.value) cloned.fields.Temperature.value = "";

  return cloned;
};

export const renderFields4BloodExam: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  const bloodTempUnits = Object.entries(BloodExamTempUnits).map(
    ([value, label]) => ({ label, value })
  );
  const bloodTempUnitValue = bloodTempUnits.find(
    (u) => u.value === record.fields?.Temperature?.unit
  )?.value;
  return (
    <React.Fragment>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorBloodPressureD"
          label="Blood Pressure: Diastolic"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4BloodExam(record);
            modifiedRecord.fields.BloodPressure.Diastolic = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.BloodPressure?.Diastolic}
        />
        <TextField
          className="recordEditorBloodPressureS"
          label="Blood Pressure: Systolic"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4BloodExam(record);
            modifiedRecord.fields.BloodPressure.Systolic = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.BloodPressure?.Systolic}
        />
      </div>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorHeartPulse"
          label="Heart Pulse"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4BloodExam(record);
            modifiedRecord.fields.HeartPulse.value = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.HeartPulse?.value}
        />
        <TextField
          className="recordEditorSpO2"
          label="SpO2"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4BloodExam(record);
            modifiedRecord.fields.SpO2.value = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.SpO2?.value}
        />
      </div>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorBloodTemp"
          label="Blood Temperature"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4BloodExam(record);
            modifiedRecord.fields.Temperature.value = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.Temperature?.value}
        />
        <Select
          className="recordEditorBloodTempUnit"
          label="Unit"
          options={bloodTempUnits}
          value={bloodTempUnitValue ? bloodTempUnitValue : ""}
          onChange={(value) => {
            const modifiedRecord = cloneRecord4BloodExam(record);
            modifiedRecord.fields.Temperature.unit = value;
            handleFieldsChange(modifiedRecord);
          }}
        />
      </div>
    </React.Fragment>
  );
};

import React, { useState } from "react";
import { authService } from "src/services/AuthService";
import LoginPage from "src/pages/LoginPage";
import {HeaderEmpty} from "../Headers";
import Menu from "../Menu";
import PageLoading from "../PageLoading";
import { firebaseService } from "src/services/FirebaseService";
import "./Page.scss";
import Footer from "../Footer";

function Page({ children, className = "" ,HeaderComponent=HeaderEmpty}) {
  firebaseService.getAuth();

  const uid = authService.getUId();
  const isAuthenticated = uid ? true : false;

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <React.Fragment>
          <div className={`pageGeneralContainer ${className}`}>
              <HeaderComponent  />
              <div className="pageContentContainer">
              <Menu  />
                {children}
              </div>
              <Footer  />
              <PageLoading />
            {/* <div id="portal-modal-id" /> */}
            
          </div>
        </React.Fragment>
      ) : (
        <LoginPage />
      )}
    </React.Fragment>
  );
}

export default Page;


import React from 'react';
import './style.css'

const IcoMoonIcon = ({ name, onClick, className}) => {
  return (
    <span
      className={`icon-${name} ${className}`} // Assuming the icon class follows the format "icon-{icon-name}"
      onClick={onClick}

    />
  );
};

export default IcoMoonIcon;
import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";

import "./RecordInfoBloodGlucose.scss";

export const renderFieldInfo4BloodGlucose: IRenderFieldInfo = (
  record: IRecord
) => {
  const value = record.fields?.BloodGlucose?.value
    ? `${record.fields.BloodGlucose.value} 
  ${record.fields.BloodGlucose.unit}`
    : "";

  return (
    <React.Fragment>
      {value && (
        <div className="recordFieldInfoContainer">
          <span className="recordFieldInfoLabel">Blood Glucose:</span>
          <span className="recordFieldInfoValue">{value}</span>
        </div>
      )}
      {record.fields?.BloodGlucose?.Description && (
        <div className="recordFieldInfoContainer">
          <span className="recordFieldInfoValue">
            {record.fields.BloodGlucose.Description}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export const getRecordInfoTitle4BloodGlucose: GetRecordInfoTitleFunc = (
  record
) => {
  return "";
};

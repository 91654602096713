import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import RecordItem from "src/components/RecordItem";
import { IRecord, RecordType, getRecordInfoTitle } from "src/types/IRecord";
import Modal from "src/components/Modal";
import RecordEditor from "src/components/RecordEditor";
import RecordService from "src/services/RecordService";
import { setUserAssets, setUserRecords } from "src/reducers/dataSlice";
import Prompt from "src/components/Prompt";
import getRecordTypeIcon from "src/components/RecordItem/RecordTypeIcon";
import { setLoading } from "src/reducers/preferencesSlice";

import "./RecordPage.scss";
import Page from "src/components/Page";
import { HeaderHistory } from "src/components/Headers";

const RecordPage = () => {
  const dispatch = useDispatch();
  const allData = useSelector((state) => state.data);
  const { isWide ,pageClickActions, searchText } = useSelector((state) => state.preferences);

  const [editMode, setEditMode] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [addTypesView, setAddTypesView] = useState(false);
  const [removingItem, setRemovingItem] = useState(null);

  const recordService = new RecordService();

  const tags = useMemo(() => {
    if (!allData.selectedPersonId || !allData.persons[allData.selectedPersonId])
      return [];

    const personTags = allData.persons[allData.selectedPersonId].tags;
    return personTags ? Object.values(personTags) : [];
  }, [allData]);

  const records = useMemo(() => {
    if (!allData.selectedPersonId || !allData.persons[allData.selectedPersonId])
      return [];

    const currData = allData.persons[allData.selectedPersonId].data;
    const personTags = allData.persons[allData.selectedPersonId].tags;
    const dataRecords = currData ? Object.values(currData) : [];

    const allRecords = recordService.prepareRecords(dataRecords, tags).sort((a, b) => {
      if (!a.dateTime) return 1;
      if (!b.dateTime) return -1;
      return a.dateTime > b.dateTime ? -1 : 1;
    });
    return allRecords;
  }, [allData, tags]);

  const physicians = useMemo(() => {
    return Object.values(allData.physicians);
  }, [allData]);

  const handleRecordItemEdit = (recordItem) => {
    setEditMode(true);
    setEditingItem(recordItem);
  };

  const handleRecordItemRemove = async (recordItem) => {
    dispatch(setLoading(true));
    const result = await recordService.doRemoveRecord(recordItem, records, allData, tags);
    if (!result) {
      dispatch(setLoading(false));
      return;
    }
    const { records: modifiedRecords, personTags: modifiedPersonTags } = result;
    dispatch(setUserRecords({
      personId: allData.selectedPersonId,
      records: modifiedRecords,
      tags: modifiedPersonTags,
    }));
    dispatch(setLoading(false));
  };

  const handleModalClose = () => {
    setEditMode(false);
    setEditingItem(null);
  };

  const handlePromptClose = () => {
    setRemovingItem(null);
  };

  const handleRecordItemChange = async (modifiedRecord) => {
    setEditMode(false);
    setEditingItem(null);
    if (!allData.selectedPersonId) return;

    dispatch(setLoading(true));
    let currRecords = cloneDeep(records);
    if (!records.some(r => r.id === modifiedRecord.id)) {
      currRecords.push(modifiedRecord);
    }

    const result = await recordService.doModifyRecord(modifiedRecord, currRecords, allData, tags);
    if (!result) {
      dispatch(setLoading(false));
      return;
    }
    const { assets: modifiedAssets, records: modifiedRecords, personTags: modifiedPersonTags } = result;

    dispatch(setUserAssets(modifiedAssets));
    dispatch(setUserRecords({
      personId: allData.selectedPersonId,
      records: modifiedRecords,
      tags: modifiedPersonTags,
    }));
    dispatch(setLoading(false));
  };

  const handleRecordItemAdd = (type) => {
    const newRecord = recordService.createNewRecord(type);
    setEditMode(true);
    setEditingItem(newRecord);
    setAddTypesView(false);
  };

  const recordTypes = recordService.getRecordTypes();

  const filteredRecords = useMemo(() => {
    const text2Search = searchText.toLowerCase();
    if (!text2Search) return records;
    return records.filter(r => {
      const replacer = (key, value) => {
        if (value && typeof value === "object") {
          let replacement = {};
          let index = 0;
          for (let k in value) {
            replacement[index++] = value[k];
          }
          return replacement;
        }
        return value;
      };

      const stringified = JSON.stringify(r, replacer).toLowerCase();
      return stringified.includes(text2Search);
    });
  }, [searchText, records]);

  return (
    <Page HeaderComponent={HeaderHistory}>
      <div
        className={`recordPageContainer ${editMode ? "recordPageCovered" : ""}`}
        onClick={() => {
          Object.values(pageClickActions).forEach((action) => action());
          setAddTypesView(false);
        }}
      >
        <div className="recordsContainer">
          {filteredRecords.length > 0 ? (
            filteredRecords.map((record, rIndex) => (
              <React.Fragment key={rIndex}>
                <RecordItem
                  record={record}
                  isWide={isWide}
                  onEdit={handleRecordItemEdit}
                  onRemove={() => {
                    setRemovingItem(record);
                  }}
                  allTags={tags}
                />
                {rIndex !== filteredRecords.length - 1 && <div className="recordConnector">&nbsp;</div>}
              </React.Fragment>
            ))
          ) : (
            <div className="emptyPageContainer">
              <div className="emptyPageMessageContainer">
                <span>No Records to Show!</span>
              </div>
            </div>
          )}
        </div>
        {editingItem && (
          <Modal
            className="editRecordModal"
            onClose={handleModalClose}
            portalId="portal-modal-id"
            title={getRecordInfoTitle(editingItem.type)}
            visible={editMode}
          >
            <RecordEditor
              onChange={handleRecordItemChange}
              record={editingItem}
              allTags={tags}
              physicians={physicians}
            />
          </Modal>
        )}
        {removingItem && (
          <Prompt
            className="editRecordModal"
            onCancel={handlePromptClose}
            onOk={() => {
              setRemovingItem(null);
              handleRecordItemRemove(removingItem);
            }}
            message="Are you sure?"
          />
        )}
      </div>
    </Page>
  );
};

export default RecordPage;

import React, { useEffect, useState } from "react";
import moment from "moment";

// Assuming Genders, IPersonInfo are used for type checking, they're omitted in JSX
import {
  GenderAmbiguous,
  GenderFemale,
  GenderMale,
  PencilSquare,
  PersonCircle,
  Trash,
} from "react-bootstrap-icons";

import DefaultAvatar from "src/assets/profile-default.svg";
import BloodIcon from "src/assets/blood-icon.svg";

import "./PersonItem.scss";

const PersonItem = ({ onEdit, onRemove, person, onClick }) => {
  const [avatarUrl, setAvatarUrl] = useState(null);

  const loadAvatar = async () => {
    setAvatarUrl(person.avatarUrl ? person.avatarUrl : "");
  };

  useEffect(() => {
    loadAvatar();
  }, [person]);

  const renderGenderIcon = () => {
    let Icon = null;
    switch (person.gender) {
      case "Male":
        Icon = GenderMale;
        break;
      case "Female":
        Icon = GenderFemale;
        break;
      default:
      case "Other":
        Icon = GenderAmbiguous;
    }

    return Icon ? <Icon className="personItemGenderIcon" /> : null;
  };

  return (
    <div className="personItemContainer" >
      <div className="personItemInfoContainer" onClick={(e) => {
        // console.log("CLICKED")
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}>
        <div className="personItemAvatar">
          {avatarUrl ? (
            <img alt="" src={avatarUrl} />
          ) : (
            <PersonCircle className="personItemAvatarIcon" />
          )}
        </div>
        <div className="personItemTitleContainer">
          <div className="personItemNameContainer">
            <span className="personItemName">{person.name}</span>
            {person.gender && renderGenderIcon()}
          </div>
          {person.birthDate && (
            <div className="personItemBirth">
              <span className="personItemBirthLabel">D.O.B:</span>
              <span className="personItemBirthValue">
                {moment(new Date(person.birthDate).toISOString()).format(
                  "YYYY-MM-DD"
                )}
              </span>
            </div>
          )}
        </div>
        {person.bloodType && (
          <div className="personItemBloodInfo">
            <img alt="" src={BloodIcon} className="personItemBloodIcon" />
            <span className="personItemInfoValue">{person.bloodType}</span>
          </div>
        )}
      </div>
      {/* <div className="personItemOperations">
        <PencilSquare
          className="personItemEditButton"
          onClick={() => onEdit(person)}
        />
        <Trash
          className="personItemRemoveButton"
          onClick={() => onRemove(person)}
        />
      </div> */}
    </div>
  );
};

export default PersonItem;

import React from "react";
import { X } from "react-bootstrap-icons";

import "./RecordItemTag.scss";

interface IRecordItemTagProps {
  title: string;
  onDelete?: () => void;
}

const RecordItemTag = ({ onDelete, title }: IRecordItemTagProps) => {
  return (
    <div className="recordItemTag">
      {title}
      <X
        className="recordItemTagRemove"
        onClick={() => {
          onDelete && onDelete();
        }}
      />
    </div>
  );
};

export default RecordItemTag;

import React, { useState } from "react";
import moment from "moment";
import {
  InfoCircle,
  PencilSquare,
  PersonCircle,
  Trash,
  X,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";

import { getRecordInfoTitleFunc } from "./RecordInfoFields";
import { getRecordInfoTitle } from "src/types/IRecord";
import getRecordTypeIcon from "./RecordTypeIcon";

import "./RecordItem.scss";
import { Chip } from "@mui/material";

const RecordItem = ({
  isWide,
  onEdit,
  onRemove,
  record,
  allTags,
}) => {
  const allData = useSelector((state) => state.data);
  const dateStr = moment(record.dateTime).format("YYYY-MM-DD HH:mm");
  const typeSrc = getRecordTypeIcon(record.type);
  const infoTitleFunc = getRecordInfoTitleFunc(record.type);
  const fieldInfoTitle = infoTitleFunc ? infoTitleFunc(record) : "";
  const infoTitle = `${getRecordInfoTitle(record.type)} ${
    fieldInfoTitle ? `: ${fieldInfoTitle}` : ""
  }`;

  return (
    <div className="recordItemContainer" >
      <div className="recordItemDataContainer">
        <div className="recordItemTitleContainer">
          {/* <div className="recordItemTitle"> */}
            <div className="recordItemIconContainer">
              <img alt="" src={typeSrc} />
            </div>
            {infoTitle && <div className="recordItemTitleInfo">{infoTitle}</div>}
            {/* <div className="recordItemOperations">
              <PencilSquare
                className="recordItemEditButton"
                onClick={() => onEdit(record)}
              />
              <Trash
                className="recordItemRemoveButton"
                onClick={() => onRemove && onRemove(record)}
              />
            </div> */}
          {/* </div> */}
          
        </div>
        <div className={`recordItemInfo ${isWide?"":"recordItemInfoSmall"}`}>
          <div className="recordItemInfoStart">
            <div className="recordItemFields">
              {record.renderFieldInfo && record.renderFieldInfo(record, allData)}
            </div>

          </div>
          <div className="recordItemTagsContainer">
              {record.tags &&
                record.tags.map((tagId, index) => {
                  const tag = allTags.find((t) => t.id === tagId);
                  console.log(tag)
                  if (!tag) return null;
                  return (
                    <Chip 
                      label={tag.name}  
                      style={{ backgroundColor:tag.color, marginLeft:"5px",fontSize: "10px"}} // Set custom background and text colors here
                    />
                  );
                })}
            </div>
        </div>
        
      </div>
      <div className="recordItemDateContainer">{dateStr}</div>
    </div>
  );
};

export default RecordItem;

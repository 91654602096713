import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "../TextField";

import "./FileUpload.scss";

interface IFileUploadProps {
  onSelect: (files: any) => void;
}

export default function FileUpload({ onSelect }: IFileUploadProps) {
  const [files, setFiles] = useState<any>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    onSelect(selectedFiles);
  };

  return (
    <Stack
      className="fileUploadRow"
      direction="row"
      alignItems="center"
      spacing={2}
      onClick={(e) => e.stopPropagation()}
    >
      <TextField
        className="fileUploadText"
        value={files ? files.map((f: any) => f.name).join('" , "') : ""}
      />
      <Button
        variant="contained"
        component="label"
        className="fileUploadButton"
      >
        Select
        <input
          hidden
          accept=""
          multiple
          onChange={handleFileChange}
          type="file"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Button>
    </Stack>
  );
}

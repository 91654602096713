import React from "react";
import {
  GetRecordInfoTitleFunc,
  IRecord,
  IRenderFieldInfo,
} from "src/types/IRecord";

import "./RecordInfoBloodExam.scss";

export const renderFieldInfo4BloodExam: IRenderFieldInfo = (
  record: IRecord
) => {
  return (
    <React.Fragment>
      <div className="recordFieldInfoBloodExam">
        {record.fields?.BloodPressure && (
          <div className="recordFieldInfoContainer">
            <span className="recordFieldInfoLabel">Blood Pressure:</span>
            <span className="recordFieldInfoValue">
              {record.fields.BloodPressure.Systolic}&nbsp;/&nbsp;
              {record.fields.BloodPressure.Diastolic}
            </span>
          </div>
        )}
        {record.fields?.HeartPulse && (
          <div className="recordFieldInfoContainer">
            <span className="recordFieldInfoLabel">Heart Pulse:</span>
            <span className="recordFieldInfoValue">
              {record.fields.HeartPulse.value}
            </span>
          </div>
        )}
        {record.fields?.Temperature?.value && (
          <div className="recordFieldInfoContainer">
            <span className="recordFieldInfoLabel">Temperature:</span>
            <span className="recordFieldInfoValue">
              {record.fields.Temperature.value}
              {record.fields.Temperature.unit}
            </span>
          </div>
        )}
        {record.fields?.SpO2 && (
          <div className="recordFieldInfoContainer">
            <span className="recordFieldInfoLabel">SpO2:</span>
            <span className="recordFieldInfoValue">
              {record.fields.SpO2.value}
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export const getRecordInfoTitle4BloodExam: GetRecordInfoTitleFunc = (
  record
) => {
  return "";
};

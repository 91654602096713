import React from "react";

import { Select as MUISelect, MenuItem } from "@mui/material";

import "./Select.scss";

interface ISelectProps {
  onChange: (value: number | string | null) => void;
  options: { label: string; value: number | string }[];
  label: string;
  value: number | string | null;
  [rest: string]: any;
}

const Select = ({
  onChange,
  className,
  options,
  label,
  value,
  ...rest
}: ISelectProps) => {
  return (
    <MUISelect
      value={value}
      /* label={label} */
      onChange={(event) => {
        onChange(event.target.value);
      }}
      size="small"
      className={`selectView ${className}`}
      {...rest}
    >
      {options.map((o, index) => {
        return (
          <MenuItem key={index} value={o.value}>
            {o.label}
          </MenuItem>
        );
      })}
    </MUISelect>
  );
};

export default Select;

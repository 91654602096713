import React from "react";

import TextField from "src/components/TextField";
import {
  HandleFieldsChangeType,
  IRecord,
  IRenderFields,
} from "src/types/IRecord";
import { deepClone } from "src/utils/utils";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";

import "./RecordEditorLabTest.scss";
import { IData } from "src/types/IData";
import RecordEditorAsset from "./RecordEditorAsset";

export const cloneRecord4LabTest = (record: IRecord): IRecord => {
  let cloned = deepClone(record);
  if (!cloned.fields) cloned.fields = {};
  if (!cloned.fields.Note) cloned.fields.Note = {};
  if (!cloned.fields.results) cloned.fields.results = [];

  return cloned;
};

export const renderFields4LabTest: IRenderFields = (
  record: IRecord,
  allData: IData,
  handleFieldsChange: HandleFieldsChangeType
) => {
  const resultLen = record.fields?.LabTest?.results?.length;
  let results = resultLen ? record.fields.LabTest.results : [];
  return (
    <React.Fragment>
      <div className="recordEditorRow">
        <TextField
          className="recordEditorLabTestNote"
          label="Note"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const modifiedRecord = cloneRecord4LabTest(record);
            modifiedRecord.fields.LabTest.Note = event.target.value;
            handleFieldsChange(modifiedRecord);
          }}
          value={record.fields?.LabTest?.Note}
        />
      </div>
      {Array.from(new Array(results.length)).map((v, index) => {
        const value = index < 0 ? "" : results[index];
        return (
          <div key={index} className="recordEditorRow">
            <TextField
              className="recordEditorLabTestResultName"
              label="Test"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let modifiedRecord = cloneRecord4LabTest(record);
                let modifiedValues = deepClone(results);
                modifiedValues[index]["name"] = event.target.value;
                modifiedRecord.fields.LabTest.results = modifiedValues;
                handleFieldsChange(modifiedRecord);
              }}
              value={value.name}
            />
            <TextField
              className="recordEditorLabTestResultResult"
              label="Result"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let modifiedRecord = cloneRecord4LabTest(record);
                let modifiedValues = deepClone(results);
                modifiedValues[index]["result"] = event.target.value;
                modifiedRecord.fields.LabTest.results = modifiedValues;
                handleFieldsChange(modifiedRecord);
              }}
              value={value.result}
            />
            <TextField
              className="recordEditorLabTestResultUnit"
              label="Unit"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let modifiedRecord = cloneRecord4LabTest(record);
                let modifiedValues = deepClone(results);
                modifiedValues[index]["unit"] = event.target.value;
                modifiedRecord.fields.LabTest.results = modifiedValues;
                handleFieldsChange(modifiedRecord);
              }}
              value={value.unit}
            />
            {index === 0 ? (
              <PlusCircle
                className="recordEditorLabTestPlus"
                onClick={() => {
                  let modifiedRecord = deepClone(record);
                  let modifiedValues = deepClone(results);
                  modifiedValues.unshift({ name: "", result: "", unit: "" });
                  modifiedRecord.fields.LabTest.results = modifiedValues;
                  handleFieldsChange(modifiedRecord);
                }}
              />
            ) : (
              <DashCircle
                className="recordEditorLabTestMinus"
                onClick={() => {
                  let modifiedRecord = deepClone(record);
                  let modifiedValues = deepClone(results);
                  modifiedValues.splice(index, 1);
                  modifiedRecord.fields.LabTest.results = modifiedValues;
                  handleFieldsChange(modifiedRecord);
                }}
              />
            )}
          </div>
        );
      })}
      <RecordEditorAsset
        assets={allData.assets}
        record={record}
        handleFieldsChange={handleFieldsChange}
      />
    </React.Fragment>
  );
};

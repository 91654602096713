import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { Button } from "@mui/material";
import _ from "lodash";
import { useSelector } from "react-redux";

import { RootState } from "src/store";
import IPhysician from "src/types/IPhysician";
import {
  HandleFieldsChangeType,
  IRecord,
  needsPhysician,
} from "src/types/IRecord";
import { deepClone } from "src/utils/utils";
import DatePicker from "src/components/DatePicker";
import AutoComplete from "src/components/AutoComplete";
import RecordItemTag from "src/components/RecordItemTag";
import TextField from "src/components/TextField";
import ITag from "src/types/ITag";
import TagService from "src/services/TagService";

import "./RecordEditor.scss";

interface IRecordEditorProps {
  onChange: HandleFieldsChangeType;
  physicians: IPhysician[];
  record: IRecord;
  allTags: ITag[];
}
const RecordEditor = ({
  onChange,
  physicians,
  record,
  allTags,
}: IRecordEditorProps) => {
  const allData = useSelector((state: RootState) => state.data);
  const [recordProps, setRecordProps] = useState<IRecord>(record);
  const [recordTags, setRecordTags] = useState<ITag[]>([]);

  const handlePropChange = (prop: string, value: any): void => {
    let newRecord = deepClone(recordProps);
    newRecord[prop] = value;
    setRecordProps(newRecord);
  };

  useEffect(() => {
    const tags = TagService.getTagsByIds(recordProps.tags, allTags);
    setRecordTags(tags);
  }, [recordProps, allTags]);

  const submitRecordChanges = () => {
    onChange && onChange(recordProps);
  };

  return (
    <div className="recordEditorContainer">
      <div className="recordEditorRows">
        <div className="recordEditorRow">
          <DatePicker
            className="taskEditorDate"
            value={recordProps.dateTime}
            onChange={(value: Moment | null) => {
              if (!value) return;
              const date = value.format("YYYY-MM-DDTHH:mm");
              handlePropChange("dateTime", date);
            }}
          />
        </div>
        {false && (
          <div className="recordEditorRow">
            <TextField
              className="recordEditorDesc"
              label="Description"
              id="editor-record-desc"
              multiline={true}
              maxRows={3}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handlePropChange("desc", event.target.value);
              }}
              value={recordProps.desc}
            />
          </div>
        )}
        <div className="recordEditorRow">
          <AutoComplete
            onSelect={(text) => {
              let modifiedTagIds = deepClone(recordProps.tags);
              const tagExists = recordTags.find((t) => t.name === text);
              if (!tagExists) {
                const tagGlobalIndex = allTags.findIndex(
                  (t) => t.name === text
                );
                if (tagGlobalIndex >= 0) {
                  modifiedTagIds.push(allTags[tagGlobalIndex].id);
                } else {
                  modifiedTagIds.push(text);
                }
              }
              handlePropChange("tags", modifiedTagIds);
            }}
            suggestions={allTags ? allTags.map((t) => t.name) : []}
            title="Tags"
          />
        </div>
        {recordProps.tags && recordProps.tags.length > 0 && (
          <div className="recordEditorRow recordEditorTagsContainer">
            {recordProps.tags.map((tagId, index) => {
              const tagIndex = recordTags.findIndex((t) => t.id === tagId);
              const tagName = tagIndex < 0 ? tagId : recordTags[tagIndex].name;
              return (
                <RecordItemTag
                  key={index}
                  onDelete={() => {
                    const modifiedTagIds = recordProps.tags.filter(
                      (tId) => tId !== tagId
                    );
                    handlePropChange("tags", modifiedTagIds);
                  }}
                  title={tagName}
                />
              );
            })}
          </div>
        )}
        {needsPhysician(recordProps.type) && (
          <div className="recordEditorRow">
            <AutoComplete
              onSelect={(text) => {
                let physician = physicians.find((t) => t.name === text);
                if (!physician) {
                  physician = {
                    updateTime: moment().unix(),
                    id: text,
                    name: text,
                  };
                  physicians.push(physician);
                }
                handlePropChange("physicianId", physician.id);
              }}
              suggestions={physicians.map((p) => p.name)}
              title="Physician"
              value={
                physicians.find((p) => p.id === recordProps.physicianId)?.name
              }
            />
          </div>
        )}
        {record.renderFields &&
          record.renderFields(
            recordProps,
            allData,
            (modifiedRecord: IRecord): void => {
              setRecordProps(modifiedRecord);
            }
          )}
      </div>
      <div className="recordEditorRow recordEditorSaveRow">
        <Button
          className="recordEditorSaveButton"
          onClick={() => submitRecordChanges()}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default RecordEditor;

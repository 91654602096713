import { IData } from "src/types/IData";
import ITag, { createNewTag } from "src/types/ITag";
import { deepClone } from "src/utils/utils";
import { firebaseService } from "./FirebaseService";
import { authService } from "./AuthService";

class TagService {
  //   public static getTagById = (tagId: string, tags: ITag[]): ITag | null => {
  //     // const tag = tags.find(t.id ?)
  //     // return tags[tagId] ? tags[tagId] : null;
  //   };
  public static getTagsByIds = (tagIds: string[], allTags: ITag[]): ITag[] => {
    let tags: ITag[] = [];
    tagIds.forEach((tId) => {
      const tagIndex = allTags.findIndex((t) => t.id === tId);
      if (tagIndex < 0) return;
      tags.push(allTags[tagIndex]);
    });
    return tags;
  };

  public static storePersonTags = async (
    selectedPersonId: string,
    modifiedTagIds: string[],
    existingTags: ITag[],
    allTags: ITag[]
  ) => {
    const finalTagIds: string[] = deepClone(modifiedTagIds);
    let allTagIds = new Set<string>();
    existingTags.forEach((t) => {
      allTagIds.add(t.id);
    });
    finalTagIds.forEach((tId) => {
      allTagIds.add(tId);
    });

    let modifiedPersonTags: ITag[] = [];
    const allTagIdsArray = Array.from(allTagIds) as string[];
    allTagIdsArray.forEach((tId: string) => {
      const tagIndex = allTags.findIndex((t) => t.id === tId);
      if (tagIndex >= 0) {
        modifiedPersonTags.push(deepClone(allTags[tagIndex]));
      } else {
        let newTag = createNewTag(tId);
        modifiedPersonTags.push(newTag);
        const recordTagIndex = finalTagIds.findIndex((rtId) => rtId === tId);
        if (recordTagIndex >= 0) finalTagIds[recordTagIndex] = newTag.id;
      }
    });

    await firebaseService.updatePersonTags(
      authService.getUId(),
      selectedPersonId,
      modifiedPersonTags
    );

    return { modifiedPersonTags, finalTagIds };
  };
}

export default TagService;
